import { lazy, useState, useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
} from "shared/providers/router/router.package";

import ThemeProvider from "shared/providers/styled-components/theme.provider";
import ToastProvider from "shared/providers/toast/toast.provider";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { portfolioVar } from "../../common/apollo/vars/portfolio.var";
import { languageIdVar } from "../../common/apollo/vars/language-id.var";
import {
  ILanguageOptionVar,
  languageOptionsVar,
} from "../../common/apollo/vars/language-options.var";
import { themeVar } from "../../common/apollo/vars/theme.var";

import { Maintenance } from "shared/components/maintenance";
import { NotFound } from "shared/components/not-found";
import { Spinner } from "shared/components/spinner";
import { Helmet } from "../helmet";

import ProtectedRoute from "../../hoc/protected-route/protected-route.hoc";
import PropertyProtectedRoute from "../../hoc/property-protected-route/property-protected-route.hoc";

import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";

import { useValidatePortfolioUrlQuery } from "../../generated/graphql";

import {
  WEB_AUTH_TOKEN,
  WEB_AUTH_PROPERTY,
} from "shared/common/data/constants";
import {
  PERSISTENT_SELECTED_LANGUAGE,
  WORK_ORDER_GUEST_FORM_SLUG,
} from "../../common/data/constants";
import { IAppProps } from "./app.interfaces";

import { setLocalStorageItem } from "shared/utils/local-storage";

import transformTranslations from "shared/utils/i18next/transform-translations";
import changeLanguage from "shared/utils/i18next/change-language";
import getCurrentDomain from "shared/utils/get-current-domain";

import GlobalStyles from "shared/styles";

// Swiper
import "swiper/css";
import "swiper/css/pagination";

const Login = withSuspense(
  lazy(() => import("../../pages/login/login.page")),
  false,
  "100vh"
);

const PortfolioSelector = withSuspense(
  lazy(() => import("../../pages/portfolio-selector/portfolio-selector.page")),
  false,
  "100vh"
);

const PropertySelector = withSuspense(
  lazy(() => import("../../pages/property-selector/property-selector.page")),
  false,
  "100vh"
);

const ForgottenPassword = withSuspense(
  lazy(() => import("../../pages/forgotten-password/forgotten.page")),
  false,
  "100vh"
);

const ResetPassword = withSuspense(
  lazy(() => import("../../pages/reset-password/reset.page")),
  false,
  "100vh"
);

const WorkOrderGuestForm = withSuspense(
  lazy(() => import("../../pages/work-orders/guest-form/guest-form.page")),
  false,
  "100vh"
);

const PropertyRoutes = withSuspense(
  lazy(() => import("../../pages/property.routes")),
  false,
  "100vh"
);

const StripeOAuth = withSuspense(
  lazy(() => import("../../pages/stripe-oauth/stripe-oauth.page")),
  false,
  "100vh"
);

const App = ({ isMaintenance }: IAppProps) => {
  const theme = useReactiveVar(themeVar);

  const [domainLoaded, setDomainLoaded] = useState(false);

  const { data, error } = useValidatePortfolioUrlQuery({
    fetchPolicy: "no-cache",
    variables: {
      url: getCurrentDomain(),
    },
    onCompleted: ({ validatePortfolioURL }) => {
      /**
       * For default URL it will return ID: null
       * If URL does not exist it will return Error
       * If URL is deleted/inactive it will return Error
       * If API does not throw Error we assume its either Default or Custom portfolio
       * So we just need to check if it has ID and set portfolioVar, otherwise proceed with defaults
       */

      if (validatePortfolioURL.id) {
        portfolioVar({
          id: validatePortfolioURL.id,
          title: validatePortfolioURL.title || "Building Hub",
          url: validatePortfolioURL.url || getCurrentDomain(),
          logo: validatePortfolioURL.logo || null,
          background: validatePortfolioURL.background || null,
          stripePublishableKey:
            validatePortfolioURL.stripePublishableKey || null,
        });

        const queryString = window.location.search;

        if (queryString) {
          const urlParams = new URLSearchParams(queryString),
            loginToken = urlParams.get("loginToken"),
            loginPropertyId = urlParams.get("loginPropertyId");

          if (loginToken) {
            setLocalStorageItem(WEB_AUTH_TOKEN, loginToken);
          }

          if (loginPropertyId) {
            setLocalStorageItem(WEB_AUTH_PROPERTY, loginPropertyId);
          }
        }
      }

      // Language detection logic
      const availableTranslations: ILanguageOptionVar[] = [];

      let language: ILanguageOptionVar | null = null;

      if (validatePortfolioURL.translations?.length) {
        validatePortfolioURL.translations.forEach(
          ({ id, title, countryCode, sources, translations }) => {
            const transformedTranslations = transformTranslations(
              sources,
              translations
            );

            if (transformedTranslations) {
              availableTranslations.push({
                id,
                title,
                countryCode,
                translations: transformedTranslations,
              });
            }
          }
        );

        if (availableTranslations.length) {
          languageOptionsVar(availableTranslations);

          const storedLanguageId = localStorage.getItem(
              PERSISTENT_SELECTED_LANGUAGE
            ),
            userLanguageCode = navigator.language.split("-")[0].toLowerCase();

          if (storedLanguageId) {
            language =
              availableTranslations.find(({ id }) => id === storedLanguageId) ||
              null;
          } else if (userLanguageCode) {
            language =
              availableTranslations.find(
                ({ countryCode }) => countryCode === userLanguageCode
              ) || null;
          }
        }
      }

      if (!language) {
        if (validatePortfolioURL.translation) {
          const transformedTranslations = transformTranslations(
            validatePortfolioURL.translation.sources,
            validatePortfolioURL.translation.translations
          );

          if (transformedTranslations) {
            language = {
              id: validatePortfolioURL.translation.id,
              title: validatePortfolioURL.translation.title,
              countryCode: validatePortfolioURL.translation.countryCode,
              translations: transformedTranslations,
            };
          }
        } else if (availableTranslations.length) {
          language = availableTranslations[0];
        }
      }

      if (language) {
        languageIdVar(language.id);
        changeLanguage(language.countryCode, language.translations);
      }

      setDomainLoaded(true);
    },
  });

  const goToGlobalURL = useCallback(
    () => window.open(process.env.REACT_APP_GLOBAL_URL, "_self"),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastProvider />
      <Helmet />

      {isMaintenance ? (
        <Maintenance />
      ) : !domainLoaded ? (
        <Spinner $height="100vh" />
      ) : error || !data?.validatePortfolioURL ? (
        <NotFound
          title="Portfolio Validation Error"
          text={
            error?.message ||
            "Application did not get valid portfolio response. Please try again or contact support."
          }
          buttonText="Go to Global Portal"
          buttonOnClick={goToGlobalURL}
        />
      ) : data.validatePortfolioURL.id ? (
        <Switch>
          <Route exact path={["/", "/login"]} children={<Login />} />

          <Route
            exact
            path="/forgotten-password"
            children={<ForgottenPassword />}
          />

          <Route
            exact
            path="/reset-password/:code"
            children={<ResetPassword />}
          />

          <Route
            exact
            path={`${WORK_ORDER_GUEST_FORM_SLUG}/:id`}
            children={<WorkOrderGuestForm />}
          />

          <Route
            exact
            path="/properties"
            children={
              <ProtectedRoute>
                <PropertySelector />
              </ProtectedRoute>
            }
          />

          <Route
            path="/properties/:id"
            children={
              <ProtectedRoute>
                <PropertyProtectedRoute>
                  <PropertyRoutes />
                </PropertyProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="/stripe-oauth"
            children={
              <ProtectedRoute>
                <StripeOAuth />
              </ProtectedRoute>
            }
          />

          {/* URLs changed so we need to handle old homepage */}
          <Route path="/dashboard" children={<Redirect to="/" />} />

          <Route children={<NotFound />} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={["/", "/login"]} children={<Login />} />

          <Route
            exact
            path="/portfolio-selector/:code"
            children={<PortfolioSelector />}
          />

          <Route children={<NotFound />} />
        </Switch>
      )}
    </ThemeProvider>
  );
};

export default App;
