import sharedTranslation from "shared/common/locales/en";

// If key is same as in shared translations we use it if value/translation is same.
// If we need to override value/translation we define it here or use different key.
// Keys should be grouped by sections.
// Keys that are used globally should be defined in global section.
// Some keys make sense to be defined in global section, but if want to allow more flexibility
// we can define them in specific section.
// Keys should be in camel case and should be descriptive.

const translation = {
  global: {
    ...sharedTranslation.global,
    toastSuccess: "Success!",
    toastApiError: "We have encountered an error. Error: {{message}}",
    toastSuccessErrorCount:
      "{{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastBulkError: "Please first select records.", // Generic error for bulk actions
    toastPauseBulkError: "Please first select records you want to pause.",
    toastActivateBulkError: "Please first select records you want to activate.",
    toastSkipBulkError: "Please first select records you want to skip.",
    toastErrorCount: "{{errorCount}} records were skipped.{{errorMessages}}",
    toastExportError: "Error while exporting data. Error: {{message}}",
    toastExportDateError: "You need to select a valid export date range",
    toastDeleteBulkError: "Please first select records you want to delete.",
    toastArchiveBulkError: "Please first select records you want to archive.",
    toastUnarchiveBulkError:
      "Please first select records you want to unarchive.",
    toastStatusBulkError:
      "Please first select records you want to update status.",
    toastAssigneeBulkError:
      "Please first select records you want to update assignee.",
    toastStatusBulkInvalidError: "Please select valid status.",
    toastPermissionError:
      "Sorry, you don't have enough permissions. Try contacting BH admin.",
    toastAllRequiredFieldsError: "Please check all required (*) fields",
    toastMarkNotificationAsReadError:
      "An error occurred while marking notification as read. Please refresh the page and try again.",
    toastMarkAllNotificationsAsReadError:
      "An error occurred while marking all notifications as read. Please refresh the page and try again.",
    toastOpenRecordManuallyError:
      "We can't open this record automatically. Please try opening it manually.",
    toastNewNotificationIndicatorError:
      "An error occurred while adding new notification in Topbar indicator. Please refresh the page and try again.",
    toastNewNotificationListError:
      "An error occurred while adding new notification in Topbar list. Please refresh the page and try again.",
    toastSuccessfullyCopied: "Successfully copied!",
    toastEnterValidMobileNumber: "Please enter valid phone number",
    toastEnterValidCode: "Please enter valid code",
    toastEditPortfolioRecordsError: "You can not edit portfolio records",
    toastAfterParsingYourFileWeReceivedZeroRecords:
      "After parsing your file we received zero records. Please double check your file or select another.",
    toastNoImportFilesFound:
      "No files found. Please go back and upload new CSV.",
    toastInvalidSkipDate:
      "Please provide valid ID and Date to skip selected date.",
    toastMissingParameters: "Required parameters are missing.",

    // Errors
    errorTitle: "Error!",
    recordNotFoundTitle: "Sorry, we could not find the record",
    recordNotFoundDescription: "Please try again later..",
    notAuthorized: "Not authorized",
    propertyAdminAccessError: "Contact your property admin to request access.",
    noFilesUploaded: "No files uploaded",
    requiredField: "This field is required",
    invalidEmail: "Please enter valid email",
    invalidURL: "Invalid URL",
    noRecords: "There are no records at this moment",
    noRecordsDescription: "You can try changing view or adding new records..",

    // Common
    yes: "Yes",
    no: "No",
    minute: "minute",
    minutes: "minutes",
    hour: "hour",
    hours: "hours",
    quantity: "qty",
    day: "day",
    days: "days",
    month: "month",
    year: "year",
    from: "From",
    to: "To",
    date: "Date",
    time: "Time",
    weather: "Weather",
    copyLink: "Copy Link",
    uploadFile: "Upload File",
    uploadFiles: "Upload Files",
    file: "File",
    files: "Files",
    sync: "Sync",
    printer: "Printer",
    order: "Order",
    selectView: "Please select the view",
    priceSelectorBillingDayText:
      "Billing day: {{billingDay}} of the {{billingPeriod}}",
    removeAll: "Remove all",
    myAccount: "My Account",
    minimize: "Minimize",
    minimizeTooltip: "Click to minimize this record.",
    goToFeature: "Go to {{title}}",
    goToDashboard: "Go to dashboard",
    searchPlaceholder: "Search..",
    selectedCount: "{{count}} selected",
    propertyTitle: "Property",
    portfolioTitle: "Portfolio",

    // Actions
    nextAction: "Next",
    backAction: "Back",
    submitAction: "Submit",
    sendAction: "Send",
    importAction: "Import",
    selectAction: "Select",
    addNewAction: "Add New",
    exportAction: "Export",
    openAction: "Open",
    openNewTabAction: "Open in a new tab",
    enableAction: "Enable",
    disableAction: "Disable",
    archiveAction: "Archive",
    unarchiveAction: "Unarchive",
    pauseAction: "Pause",
    activateAction: "Activate",
    deactivateAction: "Deactivate",
    skipAction: "Skip",
    skipNextDateAction: "Skip next date",
    scheduleAction: "Schedule",
    generateAction: "Generate",
    generateNowAction: "Generate now",
    shareAction: "Share",
    refundAction: "Refund",
    viewAction: "View",
    tableBulkStatusButton: "Status",
    tableBulkAssigneeButton: "Assignee",

    // Archiving
    isArchivedTableHeader: "Is Archived?",
    archivedTableValue: "Yes",
    unarchivedTableValue: "No",
    allArchivedOption: "All",
    activeArchivedOption: "Active",
    archivedArchivedOption: "Archived",
    filterArchivedLabel: "Show Archived",

    // Active/Inactive
    isActiveTableHeader: "Is Active?",
    activeStatus: "Active",
    inactiveStatus: "Inactive",

    // Filters
    filterAllLabel: "All", // Used for horizontal tabs, or any other filter that has 'All' option
    filterActiveLabel: "Active",
    filterInactiveLabel: "Inactive",
    filterResetButton: "Remove all filters",
    filterSaveButton: "Filter",

    // Magic Link
    invalidMagicLink: "Invalid Magic Link",
    invalidMagicLinkDescription:
      "Your magic link is invalid or expired. Please try again.",

    // Sidebar
    sidebarHome: "Dashboard",
    sidebarLock: "Lock",
    sidebarUnlock: "Unlock",
    sidebarLiveSupport: "Live Support",
    sidebarHelpCenter: "BuildingHub Help Center",
    sidebarHelpCenterURL: "https://support.buildinghub.io",
    sidebarPropertySettings: "Property Settings",
    sidebarHub: "Hub",
    hubMenuErrorTitle: "Ooops! An error occurred.",
    hubMenuErrorDescription:
      "We had an error fetching all pages. Please try again later.",

    // Notifications
    notifications: "Notifications",
    notificationsAllFilter: "All notifications",
    notificationsActivitiesFilter: "Activities only",
    notificationsCommentsFilter: "Comments only",
    notificationsMessagesFilter: "Messages only",
    markAllAsRead: "Mark all as read",
    noNotifications: "No data, you are all set!",

    // Time Modal
    timeModalTitle: "Pick date and time",
    timeModalDate: "Date",
    timeModalDatePlaceholder: "Select date",
    timeModalDateError: "Please select valid date",
    timeModalTimezone: "Timezone",
    timeModalTimezonePlaceholder: "Select timezone",
    timeModalTimezoneError: "Timezone is required",

    // Next Date Generation Modal
    nextDateModalTitle: "Update Next Date",
    nextDateModalDate: "Next Date",
    nextDateModalDatePlaceholder: "Select next date",
    nextDateModalDateError: "Please select valid next date",

    // Review Modal
    reviewModalTitle: "Leave your review",
    reviewModalCommentLabel: "Add a comment (optional)",
    reviewModalCommentPlaceholder: "Add your comment here..",
    clickMe: "Click me!",
    bad: "Bad",
    excellent: "Excellent",

    // Share Modal
    shareModalTitle: "Share or invite someone",
    shareModalSelectLabel: "Enter name or email",
    shareModalNoData: "Nothing in this moment",

    // Sync Modal
    syncModalTitle: "Sync records",
    syncModalValueName: "Record index",
    syncModalValueNamePlaceholder: "Enter value..",
    syncModalValueType: "number",

    // Visit Time Modal
    visitTimeModalTitle: "Edit visit information",

    // Printer Modal
    printerModalTitle: "Update user printer",
    printerModalPrintTooltip:
      "If no printer is selected user will be able to use any printer manually, that is locally connected.",
    printerModalPrintPlaceholder: "Print in browser",

    // User Phone Modal
    userPhoneModalTitle: "Update user phone",
    userPhoneModalPhoneStep: "Update Number",
    userPhoneModalConfirmationStep: "Confirm Number",
    userPhoneModalPhoneDescription:
      "Please enter valid mobile number below. After that you will need to verify it in order to be added to the user profile.",
    userPhoneModalConfirmationDescription:
      "Please enter the 6 digit code we sent to the mobile number.",
    userPhoneModalPhone: "Mobile Number",
    userPhoneModalPhonePlaceholder: "No mobile number",
    userPhoneModalPhoneError: "Phone is required",
    userPhoneModalCode: "Code",
    userPhoneModalCodePlaceholder: "Enter code",
    userPhoneModalCodeError: "Code is required",
    userPhoneModalResendButton: "Resend Code",
    userPhoneModalResendSuccess: "Success! We sent a new code!",

    // Predefined Broadcast Modal
    predefinedBroadcastModalTitle: "Select template",
    predefinedBroadcastTitle: "Template",
    predefinedBroadcastPlaceholder: "Select template",
    predefinedBroadcastError: "Template is required",

    //Imports
    clickToUploadButton: "Click to upload",
    importFileLabel: "CSV File",
    downloadImportTemplateButton: "Download Import Template",
    importResultsTitle: "Your import is finished",
    importResultsDescription:
      "The data has been successfully imported, empowering you with valuable insights and streamlining your operations.",
    displayAllAction: "Display All",
    displayValidOnlyAction: "Display Valid Only",
    displayInvalidOnlyAction: "Display Invalid Only",
    importStep: "Select File",
    reviewStep: "Review",
    resultsStep: "Results",
    noAttachments: "No attachments",
    oneAttachment: "1 attachment",
    multipleAttachments: "{{count}} attachments",
  },
  globalSearch: {
    toastNoPropertyError:
      "Property was not provided. We can not open this feature",
    toastNoFeatureError:
      "Feature was not provided. We can not open this feature",
    noResults: "No results",
    noRecents: "No recents",
    noFeatures: "Currently there are no searchable features.",
    searchFor: "Search for:",
    startSearching: "You can start searching by typing above..",
    openTopbarShortcutTooltip: "Open Search (CMD + K)",
    searchFeature: "Search {{feature}}",
    goToFeature: "Go to {{feature}}",
    suggestedFilters: "Suggested Filters",
    toNavigate: "to navigate",
    toSelect: "to select",
  },
  stripe: {
    // Stripe Card Modal
    cardModalTitle: "Add a card",
    cardModalName: "Name on card",
    cardModalNamePlaceholder: "Enter name",
    cardModalNameError: "Name is required",
    cardModalCardNumber: "Card Number",
    cardModalCardNumberError: "Card number is required",
    cardModalCardExpiry: "Card Expiration",
    cardModalCardExpiryError: "Card expiry is required",
    cardModalCardCvc: "Card CVC",
    cardModalCardCvcError: "Card CVC is required",
    cardModalAddressLine1: "Address Line 1",
    cardModalAddressLine1Placeholder: "Enter address",
    cardModalAddressLine1Error: "Address is required",
    cardModalAddressLine2: "Address Line 2",
    cardModalAddressLine2Placeholder: "Enter secondary address",
    cardModalPostalCode: "Postal Code",
    cardModalPostalCodePlaceholder: "Enter postal code",
    cardModalPostalCodeError: "Postal code is required",
    cardModalCity: "City",
    cardModalCityPlaceholder: "Enter city",
    cardModalCityError: "City is required",
    cardModalCountry: "Country",
    cardModalCountryPlaceholder: "Select country",
    cardModalCountryError: "Country is required",
    cardModalState: "State",
    cardModalStatePlaceholder: "Enter state",
    toastStripeError:
      "We had an error establishing Stripe connection. Please try again later or contact support.",
    toastCardError: "Please check your card information and try again.",
  },
  rrule: {
    // Modal
    updateModalTitle: "Update recurring settings",

    // Utils

    statusActive: "Active",
    statusPaused: "Paused",
    statusExpired: "Expired",

    frequencyDaily: "Day(s)",
    frequencyWeekly: "Week(s)",
    frequencyMonthly: "Month(s)",
    frequencyYearly: "Year(s)",

    endTypeDate: "Date",
    endTypeInterval: "Interval",
    endTypeNever: "Never",

    monthlyTypeMonthDay: "On day",
    monthlyTypePosDay: "Day position",

    posFirst: "First",
    posSecond: "Second",
    posThird: "Third",
    posFourth: "Fourth",
    posLast: "Last",

    weekdaySunday: "Sunday",
    weekdayShortSunday: "S",
    weekdayMonday: "Monday",
    weekdayShortMonday: "M",
    weekdayTuesday: "Tuesday",
    weekdayShortTuesday: "T",
    weekdayWednesday: "Wednesday",
    weekdayShortWednesday: "W",
    weekdayThursday: "Thursday",
    weekdayShortThursday: "T",
    weekdayFriday: "Friday",
    weekdayShortFriday: "F",
    weekdaySaturday: "Saturday",
    weekdayShortSaturday: "S",

    // Fields

    interval: "Repeat every",
    intervalPlaceholder: "Enter number",
    intervalError: "Interval is required (min: 1)",
    frequency: "Frequency",
    frequencyPlaceholder: "Select frequency",
    frequencyError: "Frequency is required",
    weeklyDays: "Days",
    weeklyDaysError: "Select at least one day",
    type: "Type",
    typeError: "Type is required",
    dayInMonth: "Day in month",
    dayInMonthPlaceholder: "Select day",
    dayInMonthError: "Month day is required",
    positionInMonth: "Position In Month",
    positionInMonthPlaceholder: "Select position",
    positionInMonthError: "Position in month is required",
    weekDay: "Day",
    weekDayPlaceholder: "Select day",
    weekDayError: "Day is required",
    endType: "Ends",
    endTypePlaceholder: "Select end type",
    endTypeError: "End type is required",
    count: "Count",
    countPlaceholder: "Enter count..",
    countError: "Count is required (min: 1)",
    endDate: "Date",
    endDateError: "Please select valid date",
  },
  activityLog: {
    title: "Activity log",
    noDataRecorded: "No data recorded",
    noChangesOccurred: "No changes occurred in this activity log.",

    // Tabs
    tabActivity: "Activity",
    tabComments: "Comments",

    // Comments
    commentPlaceholder: "Write comment here..",
    postInternalCommentAction: "Post as internal comment",

    // Print
    printLoadMoreButton: "Load more",
  },
  charges: {
    nameTableHeader: "Name",
    nameTableHeaderTooltip:
      "If type is Material then Material name is displayed. if type is Labor then Employee name is displayed.",
    quantityTableHeader: "Qty",
    total: "Total",
    billable: "Billable",
    billableTableValue: "Yes",
    notBillableTableValue: "No",
    type: "Type",
    labor: "Labor",
    record: "Record",
    existingRecords: "Existing Records",

    linkedChargeTitle: "Linked Charge",
    linkedChargeUpdateDescription:
      "This charge is linked to another record. Do you want to apply these changes in that linked record too?",
    linkedChargeDeleteDescription:
      "This charge is linked to another record. Do you want to delete this charge in that linked record too?",
    updateAll: "Update All Records",
    updateSingle: "Update This Record Only",
    deleteAll: "Delete All Records",
    deleteSingle: "Delete This Record Only",

    // Modal

    addTitle: "Add charge",
    editTitle: "Edit charge",
    whichItem: "Which item are you adding?",
    employee: "Employee",
    employeePlaceholder: "Select employee",
    employeeError: "Employee is required",
    laborRate: "Labor rate",
    laborRatePlaceholder: "Select labor rate",
    laborRateError: "Labor rate is required",
    datePerformed: "Date Performed",
    material: "Material",
    materialPlaceholder: "Select material",
    materialError: "Material is required",
    hours: "Hours",
    hoursPlaceholder: "Enter hours",
    quantity: "Quantity",
    quantityPlaceholder: "Enter quantity",
    price: "Price",
    pricePlaceholder: "Enter price",
    taxable: "Taxable?",
    markup: "Markup",
    markupPlaceholder: "No markup",
    markupAmount: "Markup Amount (per unit)",
    markupAmountPlaceholder: "No markup",
    markupTaxable: "Taxable?",
    tax: "Tax",
    taxPlaceholder: "No tax",
    taxError: "Tax is required",
    taxAmount: "Tax Amount",
    taxAmountPlaceholder: "No tax",
    notes: "Notes",
    notesPlaceholder: "Enter notes",
    baseTotalLaborLabel: "Labor ({{hours}}hrs * {{price}})",
    baseTotalMaterialLabel: "Material ({{quantity}}qty * {{price}})",
    markupTotalLabel: "Markup ({{markupAmount}} per unit)",
    taxTotalLabel: "Tax ({{taxAmount}}%)",
    totalPriceLabel: "Total price",

    // Filters

    allFilter: "All Charges",
    billableFilter: "Bil",
    notBillableFilter: "Not Billable Charges",
  },
  login: {
    header: "Log in to your account",
    description: "Enter your work email and password",
    magicDescription: "Enter your work email to start",
    verifyHeader: "Verify to login",
    verifyDescription: "Please select one option to verify login",
    verifyEmailDescription:
      "Please enter the 6 digit code you received on your email",
    verifyPhoneDescription:
      "Please enter the 6 digit code you received on your phone",
    email: "Email",
    emailPlaceholder: "Enter email",
    emailError: "Please enter a valid email",
    password: "Password",
    passwordPlaceholder: "Enter password",
    passwordError: "Password is required - should be 6 chars minimum.",
    code: "Code",
    codePlaceholder: "Enter code",
    codeError: "Please enter valid code",
    rememberMe: "Remember Me",
    resetPassword: "Reset password",
    sendMagicLink: "Send me a magic link",
    loginButton: "Login",
    verifyCode: "Verify code",
    resendCode: "Resend code",
    selectDifferentOption: "Select different option",
    or: "or",
    backToLogin: "Back to login",
    useEmailPassword: "Use email and password",
    verifyByEmail: "Verify by Email",
    verifyByPhone: "Verify by Phone",
    magicLinkSentTitle: "Magic link sent!",
    magicLinkSentText: "Check your email for your magic link.",
    alertErrorTitle: "Error!",
    alertErrorDescription:
      "Something went wrong while trying to login. Please contact BH Support if error keeps showing.",
    resendCodeSuccess: "Success! We sent a new code!",
  },
  forgottenPassword: {
    header: "Reset Password",
    description:
      "Enter your email address and we will send you a link to reset your password",
    resetButton: "Reset Password",
    backToLogin: "Remembered your password?",
    signIn: "Sign in!",
    email: "Email",
    emailPlaceholder: "Enter email",
    emailError: "Please enter a valid email",
    emailSentTitle: "Email sent!",
    emailSentText: "Check your email for further instructions.",
    alertErrorTitle: "Error!",
  },
  geneaDoors: {
    nameColumn: "Name",
    scheduleColumn: "Schedule",
    lockUnlockColumn: "Lock/Unlock",
    quickGrantColumn: "Quick Grant",
    isScheduled: "Is scheduled",
    unknownController: "Unknown Controller",
    noDataTitle: "No data",
    noDataDescription:
      "No doors available in this moment. Please try again later..",
    alreadyUnlocked: "Door is already unlocked",
    statusLocked: "Locked",
    statusUnlocked: "Unlocked",
    quickGrantYes: "Yes",
    quickGrantNo: "No",
  },
  broadcast: {
    tableTitle: "All messages",
    newTitle: "Add new message",
    informationStep: "Message Information",
    notificationsStep: "Notifications",
    sendVia: "Send via",
    sendViaError:
      "Please select how you would like this message to be sent by! (*)",
    email: "Email",
    sms: "SMS",
    app: "App",
    subject: "Subject",
    subjectPlaceholder: "Enter message subject",
    subjectError: "Subject is required",
    message: "Message",
    messagePlaceholder: "Enter message..",
    messageError: "Message is required",
    emailBody: "Email body",
    emailBodyPlaceholder: "Enter email body..",
    emailBodyError: "Email body is required",
    attachments: "Attachments",
    attachmentsTooltip:
      "Attachments will be sent only through email messages. All attachments combined can not exceed 10mb.",
    attachmentSizeError: "All attachments combined can not exceed 10mb.",
    recipientsUserColumn: "User",
    recipientsTimeColumn: "Time",
    sentVia: "Sent via",
    createdBy: "Created By",
    notifyUsersLabel: "To Users (Optional)",
    users: "Users",
    notifyCompaniesLabel: "To Company (Optional)",
    companies: "Companies",
    companiesCount: "{{count}} companies",
    allCompanies: "All companies",
    allCompaniesTooltip: "All accessible companies available for {{name}}.",
    notifyRolesLabel: "To Role (Optional)",
    roles: "Roles",
    rolesCount: "{{count}} roles",
    allRoles: "All roles",
    allRolesTooltip: "All accessible roles available for {{name}}.",
    notifyCompanyTypesLabel: "To Company Type (Optional)",
    companyTypes: "Company Types",
    companyTypesCount: "{{count}} company types",
    allCompanyTypes: "All company types",
    allCompanyTypesTooltip:
      "All accessible company types available for {{name}}.",
    noAttachments: "This message has no attachments.",
    event: "Event",
    minimizedTitle: "Message - {{subject}}",
    createdAt: "Created At",
    save: "Send Message",
    useTemplate: "Use template",
  },
  coi: {
    active: "Active",
    pending: "Pending",
    compliant: "Compliant",
    notCompliant: "Not Compliant",
    expiresToday: "Today",
    expiresSoon: "Expires Soon",
    expired: "Expired",
    pdf: "PDF",
    pdfError: "PDF is required",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    status: "Status",
    statusPlaceholder: "Select status",
    statusError: "Status is required",
    validFrom: "Valid From",
    validFromPlaceholder: "Select date",
    validFromError: "Please select valid from date",
    validTo: "Valid To",
    validToPlaceholder: "Select date",
    validToError: "Please select valid to date",
    notes: "Notes",
    notesPlaceholder: "Enter note",
    contactEmails: "Contact Emails",
    contactEmailsPlaceholder: "Enter email..",
    toggleRenew: "Toggle Renew Mode",
    toggleNormal: "Toggle Normal Mode",
    removePdf: "Remove PDF",
    renew: "Renew",
    createdBy: "Created By",
    createdByEmail: "Created By Email",
    dateCreated: "Date Created",
    expiresIn: "Expires In",
    documentTitle: "New Document",
    tableDatepickerPlaceholder: "Filter by expiration",
    filterTitle: "Filter",
    filterCompanyTypeLabel: "Company Types",
    filterCompanyTypePlaceholder: "Select company types",
    filterCompanyLabel: "Companies",
    filterCompanyPlaceholder: "Select companies",
    filterExpirationLabel: "Expiration",
    filterExpirationPlaceholder: "Select..",
  },
  companies: {
    newTitle: "Add new company",
    tenantCompanyType: "Tenant",
    vendorCompanyType: "Vendor",
    managementCompanyType: "Management",
    otherCompanyType: "Other",
    usersCountTableHeader: "Users",
    vendorIdTableHeader: "Tenant ID",
    logo: "Logo",
    title: "Name",
    titlePlaceholder: "Enter company name",
    titleError: "Company name is required",
    titleDescription: "The official name of the company",
    taxCode: "Tax ID",
    taxCodePlaceholder: "Enter tax ID",
    taxCodeDescription: "Company tax identification number",
    vendorId: "Company ID (Vendor ID)",
    vendorIdPlaceholder: "Enter ID",
    vendorIdDescription: "Company ID (Vendor ID) of the company",
    type: "Type",
    typePlaceholder: "Select company type",
    typeError: "Company type is required",
    typeDescription: "The type of company relationship",
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingDescription: "Associated building",
    floor: "Floor",
    floorPlaceholder: "Select floor",
    floorDescription: "Associated floor",
    suite: "Suite",
    suitePlaceholder: "Select suite",
    suiteDescription: "Associated suite",
    phone: "Phone",
    phonePlaceholder: "Enter phone number",
    phoneDescription: "Company contact number",
    address: "Address",
    addressPlaceholder: "Enter address",
    addressDescription: "Street address",
    city: "City",
    cityPlaceholder: "Enter city",
    cityDescription: "City name",
    country: "Country",
    countryPlaceholder: "Enter country",
    countryDescription: "Country name",
    zip: "ZIP Code",
    zipPlaceholder: "Enter ZIP code",
    zipDescription: "Postal/ZIP code",
    state: "State/Province",
    statePlaceholder: "Enter state/province",
    stateDescription: "State or province name",
    billingEmail: "Billing Email",
    billingEmailPlaceholder: "Enter billing email",
    billingEmailDescription: "Billing email address",
    billTo: "Bill To",
    billToPlaceholder: "Enter bill to",
    billToDescription: "Bill to address",
    duplicateTitle: "Duplicate Company Alert",
    duplicateDescription:
      "Similar company names found in your portfolio. Consider selecting from the list below to add to your property, rather than creating a new entry.",
    existsInPortfolio: "Exists in Portfolio",
    existsInProperty: "Exists in Property",
    existsInPropertyToast: "This company already exists in the property",
    addCompanyButton: "Add Company",
    addNewCompanyButton: "Add New Company",
    basicInformationTab: "Basic Information",
    addressTab: "Address",
    activityTab: "Activity",
    billingTab: "Billing",
    filterTitle: "Filter",
    filterActiveLabel: "Company Types",
    filterActivePlaceholder: "Select company types",
  },
  subscription: {
    billingTabTitle: "Subscriptions",
    title: "Subscription",
    statusActive: "Active",
    statusScheduled: "Scheduled",
    statusTrial: "Trial",
    statusPastDue: "Past Due",
    statusUnpaid: "Unpaid",
    statusIncomplete: "Incomplete",
    statusIncompleteExpired: "Incomplete Expired",
    statusCancelScheduled: "Cancelation Scheduled",
    statusCanceled: "Canceled",
    plan: "Plan",
    product: "Product",
    price: "Price",
    type: "Type",
    billingPeriod: "Billing Period",
    requestedBy: "Requested By",
    company: "Company",
    category: "Category",
    status: "Status",
    startDate: "Start date",
    createdOn: "Created on",
    noCoupon: "No coupon",
    waiver: "Waiver",
    viewAllWaivers: "View all waivers",
    waiverTableTitle: "All Waivers",
    waiverTitleTableHeader: "Waiver Template Title",
    waiverActiveTableHeader: "Active",
    waiverActiveTableValue: "Active",
    waiverInactiveTableValue: "Inactive",
    waiverLinkTableHeader: "Link",
    waiverLinkTableValue: "Waiver Link",
    waiverNoLinkTableValue: "No PDF",
    cancelTitle: "Cancel Subscription",
    cancelDescription:
      "Please select the time when you want cancelation to happen.",
    cancelNow: "Now",
    cancelNextCharge: "On next charge", // Used with date: "On next charge (MM/DD/YYYY)"
    cancelType: "Cancelation Type",
    cancelTypeError: "Please select cancelation type",
    switchTitle: "Switch Subscription Plan",
    switchPriceButton: "Select",
    switchPriceError: "Price is required",
    switchNoPrices:
      "Sorry, this product does not have any prices eligible to switch to.",
    switchCouponRemovedToast: "Coupon removed successfully.",
    switchCouponErrorToast: "Please enter valid coupon.",
    switchApplyCouponButton: "Apply coupon",
    filterTitle: "Filter Subscriptions",
    filterProductLabel: "Products",
    filterProductPlaceholder: "Select products",
    filterStatusLabel: "Status",
    filterStatusPlaceholder: "Select status",
    filterRequestedByLabel: "Requested By",
    filterRequestedByPlaceholder: "Select users",
    filterCompanyLabel: "Companies",
    filterCompanyPlaceholder: "Select companies",
    filterBillingPeriodLabel: "Billing Period",
    filterBillingPeriodPlaceholder: "Select billing period",
    successSwitchPlanToast: "Successfully switched subscription plan",
    couponTitle: "Coupon",
    coupon: "Coupon",
    couponPlaceholder: "Enter coupon",
    totalOverviewTitle: "Total",
    productPriceOverviewTitle: "Product Price",
    couponDiscountOverviewTitle: "Coupon discount",
    totalPriceOverviewTitle: "Total price",
    couponTestButton: "Test coupon",
    couponDiscountSuccessToast:
      "Success! You have been granted {{amount}} discount!",
    minimizedTitle: "Subscription #{{index}}",
    cancelAction: "Cancel",
    switchPlanAction: "Switch Plan",
    chargesTitle: "Charges",
    attachments: "Attachments",
    chargesAmountTableHeader: "Amount",
    chargesRefundedTableHeader: "Refunded",
    chargesInvoiceLinkTableHeader: "Invoice Link",
    chargesDateCreatedTableHeader: "Date Created",
    refundChargeAction: "Refund",
    refundChargeTitle: "Refund",
    refundChargeAmount: "Amount (Max: {{max}})",
    refundChargeAmountPlaceholder: "Enter amount to refund..",
    refundChargeAmountError: "Amount is required (Max: {{max}})",
  },
  invoices: {
    viewTitle: "Invoices",
    singleTitle: "Invoice",
    newTitle: "New invoice",
    recipientsTitle: "Update CC / BCC",
    minimizedTitle: "Invoice - {{index}}",

    payInvoice: "Pay Invoice",
    selectCard: "Select Card",
    cardRequired: "Please select a card",
    addNewCard: "Add New Card",
    payRemainingAmount: "Remaining Balance - {{amount}}",
    payOtherAmount: "Other Amount",
    amount: "Amount",
    total: "Total",
    totalPrice: "Total Price",
    serviceFee: "Service Fee",
    paymentSuccess: "Payment Successful!",
    amountError: "Amount is required",
    paymentStep: "Payment",
    confirmationStep: "Confirmation",
    invoiceDetails: "Invoice Details",
    stripeError: "Stripe is not enabled for this property.",
    creditCardPaymentError:
      "You are not allowed to pay invoices with credit card.",
    partialAmountMinError: "Amount must be greater than 0",
    partialAmountMaxError:
      "Amount must be less than or equal to the remaining balance - {{amount}}",

    company: "Company",
    companyError: "Company is required",

    stripeAccount: "Stripe Account",
    stripeAccountTooltip:
      "This is the Stripe account that will be used to receive payments for this invoice.",
    stripeAccountError: "Stripe account is required",

    email: "Email",
    emailError: "Email is required",

    cc: "CC",
    bcc: "BCC",
    ccBccDescription: "Separate emails with commas",

    invoiceDate: "Invoice Date",
    invoiceDateError: "Invoice date is required",

    dueDate: "Due Date",
    dueDateError: "Due date is required",

    notes: "Notes",
    noNotes: "No notes provided",

    billTo: "Bill To",
    noBillTo: "No bill to provided",

    attachmentsTitle: "Attachments",
    imageAttachmentsTitle: "Image Attachments",
    otherAttachmentsTitle: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",

    activityLogTitle: "Activity Log",
    informationTitle: "Information",
    chargesTitle: "Charges",
    paymentsTitle: "Payments",

    id: "ID",
    createdBy: "Created By",
    totalAmount: "Total Amount",
    status: "Status",
    openStatus: "Open",
    dueStatus: "Due",
    dueInDays: "Due in {{days}} days",
    dueToday: "Due today",
    overdueStatus: "Overdue",
    overdueDays: "Overdue {{days}} days",
    paidStatus: "Paid",
    overpaidStatus: "Overpaid",
    voidStatus: "Void",
    sentOn: "Sent on {{date}}",
    partiallyPaid: "Partially paid, {{amount}} due",

    deactivateAction: "Void",
    activateAction: "Unvoid",
    sendInvoiceAction: "Send Invoice",
  },
  invoicePayments: {
    refundPayment: "Refund Payment",
    refundAmount: "Refund Amount",
    refundAmountError:
      "Amount is required and cannot be greater than the payment amount",
    refundMinError: "Amount must be greater than 0",
    refundMaxError: "Amount cannot exceed the payment amount - {{amount}}",

    id: "ID",
    createdBy: "Name",
    amount: "Amount",
    appFee: "App Fee",
    date: "Date",
    stripeLink: "Stripe Link",

    status: "Status",
    statusSuccess: "Success",
    statusInProgress: "In Progress",
    statusPending: "Pending",
    statusFailed: "Failed",
    statusRefunded: "Refunded",
    statusPartialRefunded: "Partial Refunded",

    paymentMethod: "Payment Method",
    creditCard: "Credit Card",
    ach: "ACH",

    refunded: "Refunded",
  },
  recurringInvoices: {
    viewTitle: "Recurring Invoices",
    singleTitle: "Recurring Invoice",
    newTitle: "New recurring invoice",
    recipientsTitle: "Update CC / BCC",
    minimizedTitle: "Recurring Invoice - {{index}}",

    company: "Company",
    companyError: "Company is required",

    stripeAccount: "Stripe Account",
    stripeAccountTooltip:
      "This is the Stripe account that will be used to receive payments for this recurring invoice.",
    stripeAccountError: "Stripe account is required",

    email: "Email",
    emailError: "Email is required",

    cc: "CC",
    bcc: "BCC",
    ccBccDescription: "Separate emails with commas",

    notes: "Notes",
    noNotes: "No notes provided",

    billTo: "Bill To",
    noBillTo: "No bill to provided",

    attachmentsTitle: "Attachments",
    activityLogTitle: "Activity Log",
    chargesTitle: "Charges",
    paymentsTitle: "Payments",
    relatedRecordsTitle: "Related Records",

    id: "ID",
    createdBy: "Created By",
    totalAmount: "Total Amount",
    status: "Status",
    dateCreated: "Date Created",
    startDate: "Start Date",
    previousDate: "Previous Date",
    noPreviousDate: "No previous date",
    nextDate: "Next Date",
    noNextDate: "No next date",
    skippedDates: "Skipped Dates",
    hasSkippedDates: "Yes",
    noSkippedDates: "None",
    dueAfterDays: "Due After",
    dueAfterDaysTooltip:
      "Number of days until work order is due after generation",
    dueAfterDaysPlaceholder: "Enter number of days",
    interval: "Interval",

    tableDatepickerPlaceholder: "Filter by Next Date",

    addPayment: "Add Payment",
    selectCard: "Select Card",
    cardRequired: "Please select a card",
    addNewCard: "Add New Card",
    payRemainingAmount: "Remaining Balance - {{amount}}",
    payOtherAmount: "Other Amount",
    amount: "Amount",
    total: "Total",
    totalPrice: "Total Price",
    serviceFee: "Service Fee",
    paymentSuccess: "Payment successfully added!",
    amountError: "Amount is required",
    paymentStep: "Payment",
    confirmationStep: "Confirmation",
    invoiceDetails: "Invoice Details",
    invoiceDate: "Invoice Date",
    dueDate: "Due Date",
    stripeError: "Stripe is not enabled for this property.",
    creditCardPaymentError:
      "You are not allowed to pay invoices with credit card.",
    partialAmountMinError: "Amount must be greater than 0",
    partialAmountMaxError:
      "Amount must be less than or equal to the remaining balance - {{amount}}",
  },
  adminStripeAccounts: {
    id: "ID",
    email: "Email",
    country: "Country",
  },
  stripeInvoices: {
    billingTabTitle: "Invoices",
    title: "Invoice",
    minimizedTitle: "Invoice",
    number: "Invoice Number",
    category: "Category",
    product: "Product",
    amountDue: "Amount Due",
    amountPaid: "Amount Paid",
    requestedBy: "Requested By",
    company: "Company",
    createdOn: "Created on",
    status: "Status",
    linkLabel: "Invoice Link",
    paymentsTitle: "Payments",
    paymentId: "Payment ID",
    paymentStatus: "Status",
    paymentPrice: "Price",
    paymentDate: "Payment date",
    viewPaymentAction: "View Payment",
    viewSubscriptionAction: "View Subscription",
  },
  payments: {
    billingTabTitle: "Payments",
    title: "Payment",
    invoiceTitle: "Payment Invoice: {{number}}",
    minimizedTitle: "Payment",
    requestedBy: "Requested By",
    company: "Company",
    category: "Category",
    product: "Product",
    amount: "Amount",
    plan: "Plan",
    type: "Type",
    billingPeriod: "Billing Period",
    coupon: "Coupon",
    status: "Status",
    startDate: "Start Date",
    dateCreated: "Date Created",
    createdOn: "Created on",
    sourcesTitle: "Payment Sources",
    sourceName: "Name",
    sourceBrand: "Brand",
    sourceNumber: "Number",
    viewInvoiceAction: "View Invoice",
    viewSubscriptionAction: "View Subscription",
    succeededStatus: "succeeded",
  },
  adminProducts: {
    createdAt: "Date created",
    createdBy: "Created by",
    billingPeriodDaily: "Daily",
    billingPeriodWeekly: "Weekly",
    billingPeriodMonthly: "Monthly",
    billingPeriodYearly: "Yearly",
    priceTypeTrial: "Trial",
    priceTypeOneTime: "One Time",
    priceTypeRecurring: "Recurring",

    // Product
    detailsStep: "Details",
    pricingStep: "Pricing",
    accessStep: "Access",

    addProductTitle: "Add new product",
    editProductTitle: "Edit product",

    title: "Name",
    titlePlaceholder: "Enter product name",
    titleError: "Name is required",
    description: "Description",
    descriptionPlaceholder: "Enter description..",
    stripeAccount: "Stripe Account",
    stripeAccountTooltip:
      "This is the Stripe account that will be used to receive payments for this product.",
    stripeAccountError: "Stripe account is required",
    waiverTemplate: "Waiver Template",
    waiverTemplatePlaceholder: "Select waiver template",
    customFields: "Custom Fields",
    customFieldsDescription:
      "You can add custom fields that user can fill. Please use button below to add one.",
    customField: "Field Label",
    customFieldDescription:
      "Value of this field will be used as label/title for user.",
    customFieldPlaceholder: "Enter field label",
    customFieldRequired: "Required?",
    customFieldError: "Label is required",
    customFieldAddButton: "Add New Custom Field",
    prices: "Prices",
    priceActive: "Active",
    noPrices: "This product does not have any prices yet. Please add one.",
    newPriceButton: "Add new price",
    userSelectLabel: "To Users (Optional)",
    companySelectLabel: "To Company (Optional)",
    companyTypeSelectLabel: "To Company Type (Optional)",
    roleSelectLabel: "To Role (Optional)",
    users: "Users",
    companies: "Companies",
    roles: "Roles",
    companyTypes: "Company Types",

    toastMinOnePriceError: "Please add atleast one product price.",

    // Price
    addPriceTitle: "Add new price",
    editPriceTitle: "Edit price",

    priceTitle: "Name",
    priceTitlePlaceholder: "Enter price name",
    priceTitleError: "Name is required",

    priceDescription: "Description",
    priceDescriptionPlaceholder: "Enter description..",

    priceType: "Type",
    priceTypePlaceholder: "Select type",
    priceTypeError: "Type is required",

    priceTrialDays: "Trial Days",
    priceTrialDaysPlaceholder: "Enter number of days",
    priceTrialDaysError: "Days are required (min 1)",

    priceAmount: "Price",
    priceAmountPlaceholder: "Enter price",
    priceAmountError: "Price is required (min 0)",

    priceBillingPeriod: "Billing Period",
    priceBillingPeriodPlaceholder: "Select billing period",
    priceBillingPeriodError: "Billing period is required",

    priceBillingDay: "Billing Day",
    priceBillingDayPlaceholder: "Select day",
    priceBillingDayTooltip:
      "Please select a day of the month for your monthly payment to be drafted. If you do not select a day, the payment will be drafted each month on the same day as the initial purchase.",

    priceDateSelection: "Date selection required?",
    priceTimeSensitive: "Time sensitive price?",

    priceFrom: "From",
    priceTo: "To",

    priceTimezone: "Timezone",
    priceTimezonePlaceholder: "Select timezone",
    priceTimezoneError: "Timezone is required",

    billingDayOption: "Day {{day}} of the month",
  },
  products: {
    title: "Products",
    newSubscriptionTitle: "New Product Subscription",
    noProducts: "No products available",
    noProductsDescription: "No products available in this moment.",
    noPrices: "Product has no prices",
    noPricesDescription:
      "Product has no prices available. Please select different product.",
    unmatchedPriceError: "Error while trying to get product price.",
    total: "Total",
    couponDiscount: "Coupon discount",
    totalPrice: "Total price",

    // Fields
    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",

    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",

    date: "Start Date",
    dateError: "Please select valid start date",

    signature: "Signature",
    signatureError: "Signature is required",

    card: "{{name}}'s Card",
    cardPlaceholder: "Select card",
    cardError: "Card is required",
    addNewCardButton: "Add new card",

    coupon: "Coupon",
    couponPlaceholder: "Enter coupon..",
    applyCouponButton: "Apply coupon",

    // Waiver
    waiverTemplate: "Waiver Template",
    waiverTemplateDate: "Date",
    noWaiverTemplate: "This product does not have any waiver template.",

    // Confirmation
    oneTimeSuccess:
      "Success! You have successfully purchased {{product}}. Thank you for your purchase!",
    trialSuccess:
      "Success! You have successfully activated trial for {{product}}. Enjoy!",
    recurringSuccess:
      "Success! You have successfully subscribed to {{product}}. Enjoy!",

    // Validation messages
    signWaiverError: "Please sign the waiver",
    controlWalletError:
      "You do not have permission to access wallet of selected requester.",
    creditCardPaymentError:
      "Selected requester does not have permission to use credit card payment.",
    couponDiscountSuccess:
      "Success! You have been granted {{amount}} discount!",
    couponRemovedSuccess: "Coupon removed successfully.",
    invalidCoupon: "Please enter valid coupon.",

    // Price selector
    selectPrice: "Select",
  },
  concierge: {
    // Filters
    filterAllRecords: "All records",
    filterActiveRecords: "Active Records",
    filterInactiveRecords: "Inactive Records",
    filterExpiredRecords: "Expired Records",
    filterNotExpiredRecords: "Not Expired Records",

    // Categories
    manageCategoriesModalTitle: "Manage Categories",
    manageCategoriesTableTitle: "All Categories",
    addCategoryTitle: "Add new category",
    editCategoryTitle: "Edit category",
    categoryTitle: "Categories",
    categoryTitlePlaceholder: "Enter title",
    categoryTitleError: "Title is required",
    categoryIcon: "Icon",
    categoryIconPlaceholder: "Select icon",
    categoryIconError: "Icon is required",
    categoryOrderTableHeader: "Order",

    // Deals
    addDealTitle: "Add new deal",
    editDealTitle: "Edit deal",
    dealTitle: "Title",
    dealTitlePlaceholder: "Enter title name",
    dealTitleError: "Title is required",
    dealCategory: "Category",
    dealCategoryPlaceholder: "Select parent group",
    dealCategoryError: "Category is required",
    dealDiscount: "Discount",
    dealDiscountPlaceholder: "Enter discount",
    dealWebsite: "Website",
    dealWebsitePlaceholder: "Enter website",
    dealValidBy: "Valid by",
    dealValidByPlaceholder: "No expiration",
    dealDescription: "Description",
    dealDescriptionPlaceholder: "Add description",
    dealImage: "Image",
    dealImageError: "Image is required",
    dealPopupDescription: "Popup Description",
    dealPopupDescriptionPlaceholder: "Add popup description",
    dealCodeCheckbox: "Code - copy and paste code on the website",
    dealCode: "Code",
    dealCodePlaceholder: "Enter code",
    dealCodeError: "Code is required",
    dealLinkCheckbox: "Link - copy and visit link provided",
    dealLink: "Link",
    dealLinkPlaceholder: "Enter link (starts with: https://)",
    dealLinkError: "Link is required",
    dealPdfCheckbox: "PDF file - view and download file",
    dealPdf: "PDF",
    dealPdfError: "PDF is required",
    dealRedeemOptionError: "Please select at least one redeem option",
    dealRedeemOptions: "Redeem options (click to copy):",
    codeCopied: "Code has been copied to your clipboard!",
    linkCopied: "Link has been copied to your clipboard!",
    pdfCopied: "PDF link has been copied to your clipboard!",
    copyCode: "Copy Code",
    openLink: "Open Link",
    openPdf: "Open PDF",

    // Other
    newDealAction: "Add new record",
    newCategoryAction: "Add new category",
    manageCategoriesAction: "Manage categories",
    dealExpiresOn: "Expires on: {{date}}",
    dealNoExpiration: "No expiration",
    dealRedemptions: "redemptions",
    dealRedemptionTooltip:
      "{{uniqueCount}} unique redemption. {{totalCount}} total redemptions",
  },
  vendorAccess: {
    // Table
    id: "ID",
    tableCompanyColumn: "Vendor Name",
    tableLastCheckInColumn: "Last Check In",
    tableStatusColumn: "Status",
    coiLink: "COI Link",
    coiStatus: "COI Status",

    // Filters
    filterTitle: "Filter Vendor Access",
    filterCompanyLabel: "Company",
    filterCompanyPlaceholder: "Select company",
    filterBuildingPlaceholder: "All buildings",

    // Visit Information
    visitType: "Visit type",
    visitSingleDayOption: "Single Day",
    visitMultiDayOption: "Multi Day",
    visitPeriodOption: "Period",
    visitDateLabel: "Date",
    visitAddNewDateButton: "Add new date",
    visitDateError: "Please select valid date",
    allDayAccess: "All day access",
    startTime: "Start Time",
    startTimePlaceholder: "Select time",
    startTimeError: "Please select valid start time",
    endTime: "End Time",
    endTimePlaceholder: "Select time",
    endTimeError: "Please select valid end time",
    timezone: "Timezone",
    timezonePlaceholder: "Select timezone",
    timezoneError: "Please select valid timezone",
    notes: "Notes",
    notesPlaceholder: "Enter notes",

    // New Guest
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required.",
    requestedBy: "Host",
    requestedByPlaceholder: "Select host",
    requestedByError: "Host is required",
    company: "Host Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    coi: "COI",
    coiPlaceholder: "Select COI",
    coiError: "COI is required",
    notifyUsers: "Notify Users",
    notifyUsersPlaceholder: "Select users to notify",
    newVendorAccessInformationStep: "Vendor Information",
    newVendorAccessVisitInformationStep: "Visit Information",
    newVendorAccessNotificationsStep: "Notifications",
    newVendorAccessTitle: "Register Vendor",

    // Notifications
    notifyButton: "Notify",
    notifyPreview: "Notify {{count}} users",

    // Actions & Messages
    checkInAction: "Check-in",
    printBadgeAction: "Print badge",
    activateAction: "Activate Vendor",
    approveAction: "Approve Vendor",
    cancelAction: "Cancel Guest",
    bulkCancelStatusAction: "Cancel",
    bulkApproveStatusAction: "Approve",
    canCheckInToday: "Vendor can check-in today",
    alreadyCheckedIn: "Vendor already checked-in today",
    cannotCheckIn: "Vendor can not check-in today",

    toastCheckInSuccess: "Check-in successful!",
    toastCheckInSuccessWithPrint: "Check-in successful! Printing badge..",
    toastCheckInSuccessErrorCount:
      "Check-in successful! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastCheckInError: "Check-in error!{{errorMessages}}",
    toastCheckInErrorCount:
      "Check-in error! {{errorCount}} records were skipped.{{errorMessages}}",
    toastBulkCheckInError: "Please first select records you want to check-in.",

    toastPrintSuccess: "Print successful!",
    toastPrintError: "Print error!",
    toastPrintSuccessErrorCount:
      "Print successful! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastPrintErrorCount:
      "Print error! {{errorCount}} records were skipped.{{errorMessages}}",
    toastPrintNotification:
      "Your file is being generated! We will notify you when file is ready.",
    toastPrintSuccessNotificationErrorCount:
      "Your file is being generated! We will notify you when file is ready! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastBulkPrintBadgeError:
      "Please first select records you want to print badge.",

    toastApproveVendorNote:
      "NOTE: This record has already been approved. Updating this record will change the status of this guest back to PENDING.",

    // Status
    statusActive: "Active",
    statusCanceled: "Canceled",
    statusWaitingApproval: "Waiting Approval",

    // Badge Print
    badgePrintButton: "Print",

    // Other
    floor: "Floor",
    suite: "Suite",
    visitDate: "Visit Date",
    visitTime: "Visit Time",
    visitInformation: "Visit Information", // Used in single guest page section title
    dateAllowed: "Date allowed",
    timeAllowed: "Time allowed",
    allDayAccessTooltip:
      "This user can come in any period within the selected dates",
    multiDayVisit: "Multi Day Visit",
    noNotesDefault: "No notes",
    minimizedTitle: "Vendor Access - {{code}}",
    attachments: "Attachments",
    createdBy: "Created By",
    createdAt: "Date Created",
  },
  guests: {
    // Table
    tableImportAction: "Import Guests",
    id: "ID",
    tableGuestColumn: "Guest",
    tableLastCheckInColumn: "Last Check In",
    tableStatusColumn: "Status",

    // Filters
    filterTitle: "Filter Guests",
    filterCompanyLabel: "Company",
    filterCompanyPlaceholder: "Select company",
    filterBuildingPlaceholder: "All buildings",

    // Guest Form Fields
    firstName: "First Name",
    firstNamePlaceholder: "Enter first name",
    firstNameError: "First name can not be empty!",
    lastName: "Last Name",
    lastNamePlaceholder: "Enter last name",
    lastNameError: "Last name can not be empty!",
    email: "Email",
    emailPlaceholder: "Enter email",
    emailError: "Please provide valid email address!",
    companyTitle: "Guest Company / Title",
    companyTitlePlaceholder: "Enter guest company / title",

    // Visit Information
    visitType: "Visit type",
    visitSingleDayOption: "Single Day",
    visitMultiDayOption: "Multi Day",
    visitPeriodOption: "Period",
    visitDateLabel: "Date",
    visitAddNewDateButton: "Add new date",
    visitDateError: "Please select valid date",
    allDayAccess: "All day access",
    startTime: "Start Time",
    startTimePlaceholder: "Select time",
    startTimeError: "Please select valid start time",
    endTime: "End Time",
    endTimePlaceholder: "Select time",
    endTimeError: "Please select valid end time",
    timezone: "Timezone",
    timezonePlaceholder: "Select timezone",
    timezoneError: "Please select valid timezone",
    notes: "Notes",
    notesPlaceholder: "Enter notes",

    // New Guest
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required.",
    requestedBy: "Host",
    requestedByPlaceholder: "Select host",
    requestedByError: "Host is required",
    company: "Host Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    notifyUsers: "Notify Users",
    notifyUsersPlaceholder: "Select users to notify",
    newGuestInformationStep: "Guest Information",
    newGuestVisitInformationStep: "Visit Information",
    newGuestNotificationsStep: "Notifications",
    newGuestTitle: "Register guest",
    newGuestFormAddLabel: "Add Guest", // used also for button
    newGuestFormEditLabel: "Edit Guest", // used also for button
    newGuestFormCancelButton: "Cancel",
    newGuestAllGuestsListLabel: "Guests",
    newGuestAllGuestsRemoveAllButton: "Remove all guests",
    toastNewGuestRequiredFieldsError: "Please fill required fields",
    toastNewGuestImportError:
      "Please upload valid CSV with atleast one valid guest",
    toastNewGuestMinimumError: "Please add atleast one valid guest",
    toastNewGuestValidError: "Please enter valid guest data.",
    toastNewGuestNoValidError:
      "Looks like you don't have any valid guest. Please check your guests data again.",
    toastNewGuestListErrorTooltip:
      "Any guest with error will be skipped from creation. Errors: {{errors}}",

    // Import
    importLabel: "Import Guest List",
    importDescription: "Upload your guest list using CSV template.",
    importTemplateLink: "Get the template here.",

    // Notifications
    notifyButton: "Notify",
    notifyPreview: "Notify {{count}} users",

    // Actions & Messages
    checkInGuestAction: "Check-in",
    printBadgeAction: "Print badge",
    activateGuestAction: "Activate Guest",
    approveGuestAction: "Approve Guest",
    cancelGuestAction: "Cancel Guest",
    bulkCancelStatusAction: "Cancel",
    bulkApproveStatusAction: "Approve",
    resendEmailAction: "Resend welcome email",
    canCheckInToday: "Guest can check-in today",
    alreadyCheckedIn: "Guest already checked-in today",
    cannotCheckIn: "Guest can not check-in today",

    toastCheckInSuccess: "Check-in successful!",
    toastCheckInSuccessWithPrint: "Check-in successful! Printing badge..",
    toastCheckInSuccessErrorCount:
      "Check-in successful! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastCheckInError: "Check-in error!{{errorMessages}}",
    toastCheckInErrorCount:
      "Check-in error! {{errorCount}} records were skipped.{{errorMessages}}",
    toastBulkCheckInError: "Please first select records you want to check-in.",

    toastPrintSuccess: "Print successful!",
    toastPrintError: "Print error!",
    toastPrintSuccessErrorCount:
      "Print successful! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastPrintErrorCount:
      "Print error! {{errorCount}} records were skipped.{{errorMessages}}",
    toastPrintNotification:
      "Your file is being generated! We will notify you when file is ready.",
    toastPrintSuccessNotificationErrorCount:
      "Your file is being generated! We will notify you when file is ready! {{successCount}} records were updated. {{errorCount}} records were skipped.",
    toastBulkPrintBadgeError:
      "Please first select records you want to print badge.",

    toastApproveGuestNote:
      "NOTE: This record has already been approved. Updating this record will change the status of this guest back to PENDING.",

    // Status
    statusActive: "Active",
    statusCanceled: "Canceled",
    statusWaitingApproval: "Waiting Approval",

    // Badge Print
    badgePrintButton: "Print",

    // Other
    floor: "Floor",
    suite: "Suite",
    visitDate: "Visit Date",
    visitTime: "Visit Time",
    visitInformation: "Visit Information", // Used in single guest page section title
    dateAllowed: "Date allowed",
    timeAllowed: "Time allowed",
    allDayAccessTooltip:
      "This user can come in any period within the selected dates",
    multiDayVisit: "Multi Day Visit",
    noNotesDefault: "No notes",
    minimizedTitle: "Guest - {{name}}",
    attachments: "Attachments",
    createdBy: "Created By",
    createdAt: "Date Created",

    // Watchlist Checker
    watchlistErrorTitle: "Error while checking watchlist",
    watchlistNoDataTitle: "No data",
    watchlistMultipleTitle: "These visitors appears on the watchlist",
    watchlistSingleTitle: "This visitor appears on the watchlist",
    watchlistErrorDescription:
      "There was an error while checking if guest is on watchlist. You can still check-in guest if you want by clicking button below.",
    watchlistNoMatchesDescription:
      "Guest is not on watchlist. You can still check-in guest if you want by clicking button below.",
    guestWatchlistViewRecordButton: "View record",
  },
  incidents: {
    id: "ID",
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required",
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    floor: "Floor",
    floorPlaceholder: "Select floor",
    suite: "Suite",
    suitePlaceholder: "Select suite",
    status: "Status",
    statusPlaceholder: "Select status",
    details: "Details",
    detailsPlaceholder: "Enter incident details",
    noDetailsDefault: "No details",
    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",
    issueTypeError: "Issue type is required",
    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",
    requestedByEmail: "Requested by email",
    requestedByCompany: "Requested by company",
    createdBy: "Created By",
    dateCreated: "Date Created",
    startDate: "Incident Date",
    attachments: "Attachments",
    imageAttachments: "Image Attachments",
    otherAttachments: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",
    filterTitle: "Filter Incidents",
    filterBuildingPlaceholder: "All buildings",
    minimizedTitle: "Incident - {{index}}",
    newTitle: "Create a incident",
    watchersButton: "Watchers",
    watchersPreview: "{{count}} users watching",
    customFieldsTitle: "Additional Information",
    activityLogTitle: "Activity Log",
  },
  leasingHub: {
    newTitle: "New Lease",
    title: "Lease", // Used in single lease page title
    minimizedTitle: "Lease #{{index}}",
    leaseStart: "Lease Commencement", // Instead of startDate; Used in table column header and single lease page
    leaseEnd: "Lease Expiration", // Instead of endDate; Used in table column header and single lease page
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    status: "Status",
    statusActive: "Active",
    statusExpired: "Expired",
    suites: "Suites",
    suitesPlaceholder: "Select suites",
    suitesError: "Suite is required",
    suitesCount: "{{count}} suites",
    suitesMultiple: "Multiple suites",
    chargeType: "Charge Type",
    chargeTypePlaceholder: "Select charge type",
    chargeTypeError: "Charge type is required",
    startDate: "Start Date",
    startDateError: "Start date is required",
    endDate: "End Date",
    endDateError: "End date is required",
    amount: "Amount",
    amountPlaceholder: "Enter amount",
    amountError: "Amount is required",
    amountUnitTotal: "Total",
    amountUnitMonthly: "Monthly",
    amountUnitYearly: "Yearly",
    rentFootage: "Rentable Square Footage",
    rentFootagePlaceholder: "Enter square footage",
    attachments: "Attachments",
    lateFeeAmount: "Late Fee Amount",
    lateFeeAmountPlaceholder: "Enter late fee amount",
    lateFeeUnit: "Late Fee Unit",
    lateFeeUnitValue: "$ - Amount",
    lateFeeUnitPercent: "% - Percentage",
    gracePeriod: "Grace Period",
    gracePeriodPlaceholder: "Enter number of days",
    rentTotal: "Total",
    createdBy: "Created By",
    createdAt: "Created At",
    newLeaseInformationTitleReview: "Lease Information",
    newLeaseRentTitleReview: "Rent Information",
    addRentTitle: "Add Rent",
    editRentTitle: "Edit Rent",
    rentSchedule: "Rent Schedule",

    // Steps
    informationStep: "Lease Information",
    rentStep: "Rent Information",
    reviewStep: "Review",

    // Actions & Buttons
    adderButton: "Add",
    automateAction: "Automate",
    addRentAction: "Add Rent",
    removeAllButton: "Remove All",

    // Increaser
    increaserTitle: "Automate",
    increaseAmount: "Increase By",
    increaseAmountError: "Amount is required",
    increaseByMonthOption: "$/Month",
    increaseByYearOption: "$/Year",
    increaseByPercentMonthOption: "%/Month",
    increaseByPercentYearOption: "%/Year",

    // Toasts
    toastAutomationError:
      "Automation is available only for Monthly or Yearly types.",
    toastAdderRequiredFieldsError: "Please fill in all required fields",
    toastRentRequiredFieldsError: "Please fill all rent fields",
    toastRentMinimumError: "At least one rent is required",
  },
  adminSidebar: {
    dashboardPage: "Settings",

    activityLogPage: "Activity Log",
    activityLogPageIcon: "activity",

    // Global Settings
    globalSettingsSection: "Global Settings",

    notificationsPage: "Notifications",
    notificationsPageIcon: "email",

    menuManagerPage: "Menu Manager",
    menuManagerPageIcon: "menuSettings",

    reportingSettingsPage: "Reporting Settings",
    reportingSettingsPageIcon: "report",

    guestSettingsPage: "Guest Settings",
    guestSettingsPageIcon: "person",

    printersPage: "Printers",
    printersPageIcon: "printer",

    kioskPage: "Kiosk",
    kioskPageIcon: "phone",

    devicesPage: "Devices",
    devicesPageIcon: "devices",

    // Global Data

    globalDataSection: "Global Data",

    accountingPage: "Accounting",
    accountingPageIcon: "accountBalance",

    laborRatesPage: "Labor Rates",
    laborRatesPageIcon: "moneyBill",

    employeesPage: "Employees",
    employeesPageIcon: "multiplePersons",

    predefinedBroadcastsPage: "Predefined Broadcasts",
    predefinedBroadcastsPageIcon: "message",

    propertyAddonsPage: "Property Addons",
    propertyAddonsPageIcon: "puzzle",

    stripeSection: "Stripe",
    stripeSectionIcon: "card",

    stripeAccountsPage: "Accounts",
    stripeSettingsPage: "Settings",

    // Hidden

    buildingsPage: "Buildings",
    buildingsPageIcon: "building",

    // Hidden

    importPage: "Import",
    importPageIcon: "exchange",

    // -- Materials Section

    materialsSection: "Materials",
    materialsSectionIcon: "tools",

    materialsPage: "Materials",
    materialGroupsPage: "Material Groups",

    waiversPage: "Waivers",
    waiversPageIcon: "document",

    productsPage: "Products",
    productsPageIcon: "cart",

    couponsPage: "Coupons",
    couponsPageIcon: "dollar",

    leasingChargeTypesPage: "Leasing - Charge Types",
    leasingChargeTypesPageIcon: "clipboardText",

    customFieldsPage: "Custom Fields",
    customFieldsPageIcon: "database",

    // Amenities Section

    amenitySection: "Amenities",

    // -- MeetingHub Section

    meetingHubSection: "MeetingHub",
    meetingHubSectionIcon: "chair",

    meetingHubRoomsPage: "Rooms",
    meetingHubSettingsPage: "Settings",

    // Operations Section

    operationsSection: "Operations",

    // -- COI Section

    coiSection: "COI",
    coiSectionIcon: "wrench",

    coiEmailsPage: "Email to COI",

    // -- Work Orders Section

    workOrdersSection: "Work Orders",
    workOrdersSectionIcon: "wrench",

    workOrdersIssueTypesPage: "Issue Types",
    workOrdersIssueTypesGroupsPage: "Issue Types - Groups",
    workOrdersStatusesPage: "Statuses",
    workOrdersPrioritiesPage: "Priorities",
    workOrdersGuestFormsPage: "Guest Forms",
    workOrdersEmailPage: "Email to Work Order",

    // -- Incidents Section

    incidentsSection: "Incidents",
    incidentsSectionIcon: "warningAmber",

    incidentsIssueTypesPage: "Issue Types",
    incidentsIssueTypesGroupsPage: "Issue Types - Groups",
    incidentsStatusesPage: "Statuses",

    // -- Preventive Maintenance Section

    preventiveMaintenanceSection: "Preventive Maintenance",
    preventiveMaintenanceSectionIcon: "subTask",

    preventiveMaintenanceTaskTypesPage: "Task Types",
    preventiveMaintenanceCustomIdsPage: "Custom IDs",

    // Connected Apps Section

    connectedAppsSection: "Connected apps",

    integrationsPage: "Integrations",
    integrationsPageIcon: "integration",

    amanoSettingsPage: "Amano Settings",
    amanoSettingsPageIcon: "cog",

    geneaSettingsPage: "Genea Settings",
    geneaSettingsPageIcon: "cog",

    // Leasing Hub Section

    leasingHubSection: "Leasing hub",

    // -- Stacking Plan Section

    stackingPlanSection: "Stacking plan",
    stackingPlanSectionIcon: "stack",

    stackingPlanOverviewPage: "Overview",
    stackingPlanFloorsPage: "Floors",
    stackingPlanSuitesPage: "Suites",
  },
  adminMeetingHubSettings: {
    // Page subtitle
    subtitle: "Settings",

    // Fields
    defaultWorkOrderCategory: "Default Issue Type",
    defaultWorkOrderCategoryDescription:
      "Issue Type when generating Work Orders",
    defaultWorkOrderCategoryPlaceholder: "Select issue type",
    defaultWorkOrderCategoryError: "Issue type is required",

    generateWorkOrderOnReservationApprove: "Generate Work Order on Approval",
    generateWorkOrderOnReservationApproveDescription:
      "Automatically generate Work Order when reservation is approved",

    notifyUsers: "Notify Users for Pending Approval Reservations",
    notifyUsersDescription:
      "Which users will be notified when reservation requires approval (pending).",
    notifyUsersPlaceholder: "Select user(s)..",
  },
  adminMeetingHubRooms: {
    openingHours: "Opening hours",
    closingHours: "Closing hours",

    // Sizes
    largeSize: "Large",
    mediumSize: "Medium",
    smallSize: "Small",

    // Rates
    halfFullDayRate: "Period",
    hourlyRate: "Hourly",

    // Intervals
    halfHourInterval: "Per half hour",
    hourInterval: "Per hour",

    // Single Room Modal Steps
    detailsStep: "Details",
    pricingStep: "Pricing",
    termsStep: "Terms & Addons",

    // Single Room Modal Fields
    addRoomTitle: "Add Room",
    editRoomTitle: "Edit Room",
    title: "Name",
    titlePlaceholder: "Enter name",
    titleError: "Name is required",
    subtitle: "Subtitle",
    subtitlePlaceholder: "Enter subtitle",
    image: "Image",
    imageError: "Image is required",
    color: "Color",
    size: "Room Size",
    sizePlaceholder: "Select size",
    sizeError: "Room size is required",
    connectedRooms: "Connected Rooms",
    connectedRoomsPlaceholder: "Select rooms",
    glAccount: "GL Account",
    glAccountPlaceholder: "Select GL account",
    arCode: "AR Code",
    arCodePlaceholder: "Select AR code",
    chargeCode: "Charge Code",
    chargeCodePlaceholder: "Select charge code",
    requiresApproval: "Requires approval",
    timeFrom: "Available from",
    timeTo: "Available to",
    timeSelectPlaceholder: "Select time",
    timeError: "Please select valid time",
    advanceDays: "# of Days in advance that room can be booked",
    advanceDaysTooltip: "Leave empty if you do not want to set the limit",
    advanceDaysPlaceholder: "Enter amount of days",
    advanceDaysError: "Value must be greater than 0",

    amenities: "Room amenities",
    amenityName: "Amenity Name",
    amenityNamePlaceholder: "Amenity name",
    amenityNameError: "Please enter amenity name",
    amenityIcon: "Icon",
    amenityIconPlaceholder: "Select icon",
    amenityIconError: "Please select an icon",
    addAmenityAddonButton: "Add +",

    priceModel: "Pricing model",
    priceModelPlaceholder: "Select price model",
    priceModelError: "Please select pricing model",

    rate: "Rate",
    ratePlaceholder: "Enter rate",
    rateError: "Please enter rate",

    interval: "Interval",
    intervalPlaceholder: "Select interval",
    intervalError: "Please select interval",

    halfDayRate: "Half-Day Rate",
    halfDayRatePlaceholder: "Enter half-day rate",
    halfDayRateError: "Enter half-day rate",
    fullDayRate: "Full-Day Rate",
    fullDayRatePlaceholder: "Enter full-day rate",
    fullDayRateError: "Enter full-day rate",
    maxRate: "Max rate",
    maxRatePlaceholder: "Enter max rate",
    halfDayRateLimit: "Half-Day Rate Limit",
    halfDayRateLimitPlaceholder: "Select half-day rate limit",
    halfDayRateLimitError: "Please select half-day rate limit",

    minBookingTime: "Minimum Booking Time",
    minBookingTimePlaceholder: "Select minimum time",
    minBookingTimeError: "Please select minimum booking time",
    maxBookingTime: "Maximum Booking Time",
    maxBookingTimePlaceholder: "Unlimited",
    bufferTime: "Buffer Time",
    bufferTimePlaceholder: "No buffer time",

    cancellationPeriod: "Cancellation Period",
    cancellationPeriodPlaceholder: "No cancellation time",

    liabilityPolicy: "Liability Policy",
    liabilityPolicyPlaceholder: "Enter liability policy",
    cancellationPolicy: "Cancellation Policy",
    cancellationPolicyPlaceholder: "Enter cancellation policy",
    notes: "Notes",
    notesPlaceholder: "Enter notes",

    addonsLabel: "Addons",
    material: "Material",
    materialPlaceholder: "Select material",
    addonPricePerReservation: "Addon price per reservation",
  },
  meetingHub: {
    requiresApprovalWarning: "This room requires approval",
    detailsStep: "Details",
    additionalDetailsStep: "Additional Details",
    paymentStep: "Payment",
    reviewStep: "Review and Submit",
    room: "Room",
    date: "Date",
    time: "Time",
    from: "From",
    to: "to",
    timezone: "Timezone",
    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    addons: "Add-ons",
    detailsLabel: "Any other details for any other requests",
    detailsPlaceholder: "Add your message here..",
    attachments: "Attachments",
    paymentMethodDescription: "Which payment are you using?",
    paymentMethodCreditCard: "Credit Card",
    paymentMethodWorkOrder: "Bill through Work Order",
    paymentMethodWorkOrderMessage:
      "Your reservation will be processed through the Work Order.",
    stripeError:
      "Selected payment method is not available. Stripe is not integrated.",
    walletPermissionError:
      "Selected payment method is not available. You do not have permission to access wallet of selected requester.",
    creditCardPermissionError:
      "Selected payment method is not available. Selected requester does not have permission to use credit card payment.",
    offlineError:
      "Selected payment method is not available. Selected requester does not have permission to use offline payment.",
    card: "{{name}}'s Card",
    cardPlaceholder: "Select card",
    cardError: "Card is required",
    roomDetails: "Room Details",
    paymentByWorkOrder:
      "You have chosen billing by Work Order. Charges will be applied to Rent Statement. (Company Administrators ONLY)",
    paymentByCreditCard: "You have chosen billing by Credit Card.",
    paymentApproval:
      "Your credit card will be charged once booking has been approved.",
    termsConditions: "Terms & Conditions",
    termsAgree: "I agree to the Terms & Conditions",
    total: "Total",
    totalPrice: "Total price",
    liabilityPolicy: "Liability Policy",
    cancellationPolicy: "Cancellation Policy",
    notes: "Notes",
    termsError: "The terms and conditions must be accepted.",
    permissionsError:
      "We could not verify if requester has required permissions to use offline and credit card payments.",
    customFieldsError:
      "Some custom fields might not be available. Error: {{error}}",
    roomNotFoundError: "Sorry, we could not find the room",
    roomNotFoundDescriptionError: "Please try again later..",
    payment: "Payment",
    bookNowButton: "Book now",
    timeTakenError:
      "You selected the time that's already taken. Please change the time.",
    tooLateError: "You are too late. Time has already passed.",
    advanceDaysError: "Bookings can be made up to {{days}} days in advance.",
    minBookingError: "Minimum booking time is: {{minutes}} minutes.",
    maxBookingError: "Maximum booking time is: {{minutes}} minutes.",
    selectTimeError: "Please select a time",
    selectDateError: "Please select the date first",
    noRooms: "There are no rooms available at this moment.",
    dateRequiredError: "Date is required!",
    allSizesFilter: "All sizes",
  },
  meetingHubReservations: {
    id: "ID",
    tableDatepickerPlaceholder: "Filter by Reservation Date",
    activeStatus: "Active",
    canceledStatus: "Canceled",
    deniedStatus: "Denied",
    pendingStatus: "Pending",
    processingStatus: "Processing",
    requiresActionStatus: "Requires Action",
    requiresCaptureStatus: "Requires Capture",
    requiresConfirmationStatus: "Requires Confirmation",
    requiresPaymentMethodStatus: "Requires Payment Method",
    succeededStatus: "Succeeded",
    approveAction: "Approve",
    cancelAction: "Cancel",
    denyAction: "Deny",
    minimizedTitle: "Room Reservation - {{index}}",
    generateWorkOrderNowAction: "Generate Work Order (Now)",
    generateWorkOrderScheduleAction: "Generate Work Order (Schedule)",
    generateWorkOrderSuccess:
      "Success! Click to open new Work Order - {{index}}",
    syncWorkOrderAction: "Sync Work Order",
    unsyncWorkOrderAction: "Unsync Work Order - {{index}}",
    syncWorkOrderValueName: "Work Order Index",
    paymentType: "Payment type",
    paymentTypeWorkOrder: "Work Order",
    paymentStatus: "Payment status",
    paymentLink: "Payment link",
    paymentLinkText: "View payment",
    reservationDate: "Reservation Date",
    reservationTime: "Reservation Time",
    date: "Date",
    time: "Time",
    to: "to",
    total: "Total",
    company: "Company",
    status: "Status",
    room: "Room Name",
    timezone: "Property Timezone",
    chargesTitle: "Charges & Addons",
    requestedBy: "Requested By",
    requestedByEmail: "Requested by email",
    requestedByCompany: "Requested by company",
    createdAt: "Date Created",
    createdBy: "Created By",
    printDetails: "Details",
    details: "Details for any other requests",
    detailsPlaceholder: "Enter room reservation details",
    customFieldsTitle: "Additional Information",
    activityLogTitle: "Activity Log",
    attachments: "Attachments",
    imageAttachments: "Image Attachments",
    otherAttachments: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",
  },
  dashboard: {
    editMode: "Edit mode",
    restaurants: "Restaurants",
    hotels: "Hotels",
    transit: "Transit",
    atms: "ATMs",
    addWidgetTitle: "Add Widget",
    noWidgets: "No widgets available",
    noWidgetsDescription: "Currently there are no widgets available.",
    widgetTypeError: "Please select a widget type",
    widgetTypeDescription:
      "Click here to add {{title}} widget. This will show all records you have access to.",
    myWidgetTypeDescription:
      "Click here to add {{title}} widget. This widget will show all records that are assigned to you.",
    title: "Title",
    titleError: "Title is required",
    icon: "Icon",
    iconError: "Icon is required",
    color: "Color",
    colorError: "Color is required",
    myRecords: "My {{title}}",
    widgetLoadingTitle: "Widget is loading..",
  },
  news: {
    mainTitle: "News",

    description: "Post Description",
    descriptionPlaceholder: "Enter description",
    descriptionError: "Description is required",
    attachments: "Attachments",
    like: "Like",
    unlike: "Unlike",
    noLikes: "Nobody liked this post",
    addPostTitle: "Add new post",
    editPostTitle: "Edit post",
  },
  events: {
    mainTitle: "Events",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
    description: "Description",
    descriptionPlaceholder: "Enter description",
    descriptionError: "Description is required",
    location: "Location",
    locationPlaceholder: "Enter location",
    eventDate: "Start Date",
    eventDateError: "Event date is required",
    startTime: "Start Time",
    startTimeError: "Start time is required",
    eventLength: "Event Length",
    eventLengthPlaceholder: "Enter event length",
    eventLengthError: "Event length is required",
    eventLengthTooltip:
      "Event length is the duration of the event in minutes. It must be a multiple of 15.",
    timezone: "Timezone",
    timezonePlaceholder: "Select timezone",
    timezoneError: "Timezone is required",
    capacity: "Capacity",
    capacityPlaceholder: "Enter capacity",
    showCapacity: "Show capacity",
    repeat: "Is this event repeated?",
    enableRegistration: "Enable event registration",
    attachments: "Attachments",

    addEventTitle: "Add new event",
    editEventTitle: "Edit event",

    readyForReview: "Event is ready for review. Click to add review.",

    editThisEventButton: "Edit this event",
    editAllEventsButton: "Edit all events",
    editFutureEventsButton: "Edit this + future events",

    deleteThisEventButton: "Delete this event",
    deleteAllEventsButton: "Delete all events",
    deleteFutureEventsButton: "Delete this + future events",

    addRating: "Add rating",
    yourRating: "Your Rating",
    createdBy: "Created By",
    comment: "Comment: {{comment}}",
    clickToUpdate: "Click to update.",
    youCanRateTheEventAfterItStarts: "You can rate the event after it starts.",
    averageSatisfaction: "Average satisfaction: {{rating}}",
    capacityAvailable: "{{capacity}} available",
    readMore: "Read more",
    register: "Register",
    unregister: "Unregister",
    spotsLabel: "Spots",
    spot: "spot",
    spots: "spots",

    // Attendees
    sendMessageButton: "Send Message",
    attendeesUserTableHeader: "User",
    attendeesRegisteredOnTableHeader: "Registered On",
    attendeesRatingTableHeader: "Rating",
    attendeesCommentTableHeader: "Comment",

    // New Message
    newMessageTitle: "Add new message",
    sendViaLabel: "Send via",
    sendViaError:
      "Please select how you would like this message to be sent by! (*)",
    subject: "Subject",
    subjectPlaceholder: "Enter message subject",
    subjectError: "Subject is required",
    message: "Message",
    messagePlaceholder: "Enter message..",
    messageError: "Message is required",
    email: "Email",
    sms: "SMS",
    app: "App",
  },
  propertySelector: {
    title: "Select Property",
    description: "Select a property to log in to",
    noActiveRecords:
      "No active properties available. Please try again requesting new magic link..",
    recordsCount: "{{count}} properties available",
    backButton: "Back to login",
  },
  portfolioSelector: {
    title: "Welcome!",
    description: "Select a portfolio to log in to",
    noActiveRecords:
      "No active portfolios available. Please try again requesting new magic link..",
    recordsCount: "{{count}} portfolios available for {{email}}",
    notYou: "Not you?",
    backButton: "Use a different email",
  },
  equipment: {
    equipmentView: "Equipment",
    minimizedTitle: "Equipment - {{title}}",
    allEquipmentTableTitle: "All Equipment",
    newEquipmentButton: "New Equipment",
    newCategoryButton: "New Category",
    manageCategoriesButton: "Manage Categories",

    include: "Include",
    only: "Only",
    exclude: "Exclude",
    serviceHistory: "Service History",
    workOrders: "Work Orders",

    id: "ID",
    count: "Count",
    status: "Status",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
    category: "Category",
    categoryPlaceholder: "Select category",
    categoryError: "Category is required",
    building: "Building",
    buildingPlaceholder: "Select buildings",
    floor: "Floor",
    floorPlaceholder: "Select floor",
    suite: "Suite",
    suitePlaceholder: "Select suite",
    specificLocation: "Specific Location",
    specificLocationPlaceholder: "Enter location",
    assetTag: "Asset Tag",
    assetTagPlaceholder: "Enter asset tag",
    serialNumber: "Serial Number",
    serialNumberPlaceholder: "Enter serial number",
    manufacturer: "Manufacturer",
    manufacturerPlaceholder: "Enter manufacturer name",
    attachments: "Attachments",
    details: "Details",
    detailsPlaceholder: "Enter equipment details",
    noDetailsDefault: "No details",

    printQRCode: "Print QR Code",
    generateQRCode: "Generate QR Code (PDF)",

    filterTitle: "Filter Equipment",
    addEquipmentTitle: "Add Equipment",
    allCategoriesTableTitle: "All Equipment Categories",
    allCategoriesTableSubtitle: "All Categories",
    addCategoryTitle: "Add Category",
    editCategoryTitle: "Edit Category",

    nonBuildingRecordsLabel: "Records without default building",
    nonBuildingRecordsTooltip:
      "Not all equipment records have default building. You can choose to include, exclude or select only those records.",
  },
  procedures: {
    proceduresView: "Procedures",
    id: "ID",
    allProcedures: "All Procedures",
    procedures: "Procedures",
    singleProcedure: "Single Procedure",
    addProcedure: "Add Procedure",
    addSection: "Add Section",
    addStep: "Add Step",
    addStepCheckbox: "Checkbox",
    addStepInput: "Text Input",
    addStepNumberInput: "Number Input",
    addStepDropdown: "Dropdown",
    addStepPhoto: "Photo",
    stepHasCustomId: "Has custom ID?",
    stepHasCustomIdTooltip:
      "By adding custom ID, you can mark, specify or group step(s).",
    stepCustomIdPlaceholder: "Enter custom ID",
    stepHasInputUnit: "Has input unit?",
    stepHasInputUnitTooltip:
      "You can add unit to the input field. This unit will be displayed next to the input field.",
    stepInputUnitPlaceholder: "Enter unit",
    sections: "Sections",
    sectionTitlePlaceholder: "Enter section title",
    sectionTitleError: "Section title is required",
    noSectionsError: "There are no sections. Please add one.",

    stepTitlePlaceholder: "Enter step title (label)",
    stepDropdownPlaceholder: "Create dropdown items",
    stepDropdownNoOptionsMessage: "Start typing to create new item..",
    stepDropdownIsMulti: "Multi select?",
    stepDropdownIsMultiTooltip:
      "By default this step will allow selecting one option. If you want to enable multi select, please check this option.",
    stepTitleError: "Step title is required",
    stepDropdownItemsError: "Atleast one item is required",
    stepTitleAndDropdownError: "Step title and atleast one item is required",
    noStepsError: "There are no steps. Please add one.",

    schedulesCount: "Schedules Count",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    estimatedLabor: "Estimated Labor",
    estimatedLaborPlaceholder: "Enter estimated labor",
    estimatedLaborTooltip:
      "Estimated labor is the time it takes to complete the procedure in minutes.",

    createdBy: "Created By",
    dateCreated: "Date Created",
    status: "Status",

    notes: "Notes",
    notesPlaceholder: "Enter procedure notes",

    minSectionsError: "Atleast one section is required!",
    sectionDataStructure:
      "Every section must have title and atleast one step! Every step must have title!", // used as a tooltip or toaster for validation
  },
  schedules: {
    schedulesView: "Schedules",
    minimizedTitle: "Schedule - #{{index}}",
    newScheduleTitle: "New Schedule",
    addEquipmentTitle: "Add Equipment",
    addProcedureTitle: "Add Procedure",
    tableDatepickerPlaceholder: "Filter by Next Date",

    scheduleStep: "Schedule",
    detailsStep: "Details",
    equipmentStep: "Equipment",

    id: "ID",
    title: "Name",
    titlePlaceholder: "Enter name",
    titleError: "Name is required",
    status: "Status",
    notes: "Schedule Notes",
    notesPlaceholder: "Enter schedule notes",
    notesError: "Notes are required",
    noNotesDefault: "No schedule notes",
    attachments: "Attachments",

    date: "Date",
    startDate: "Start Date",
    nextDate: "Next Date",
    previousDate: "Previous Date",
    noPreviousDate: "No previous date",
    noNextDate: "No next date",
    dateCreated: "Date Created",
    interval: "Interval",
    timezone: "Timezone",
    timezonePlaceholder: "Select timezone",

    building: "Building",
    buildingPlaceholder: "Select buildings",
    buildingError: "Building is required",
    floor: "Floor",
    suite: "Suite",

    estimatedLabor: "Estimated Labor",
    dueAfter: "Due After",
    dueAfterDays: "Due After",
    dueAfterDaysPlaceholder: "Enter number of days",
    dueAfterDaysTooltip: "Number of days until task is due after generation",
    taskType: "Task Type",
    taskTypePlaceholder: "Select task types",
    assignedTo: "Assigned To",
    assignedToPlaceholder: "Select assignee",
    createSeparateTask: "Create separate task for each selected equipment?",

    procedure: "Procedure",
    procedurePlaceholder: "Select procedure",
    procedureError: "Procedure is required",
    procedures: "Procedures",
    proceduresPlaceholder: "Select procedures",
    proceduresError: "Atleast one procedure is required",

    equipment: "Equipment",
    equipmentPlaceholder: "Select equipment",
    equipmentError: "Equipment is required",
    equipmentCategoryFilterPlaceholder: "Select category",
    includeEquipment: "Include?",

    createdBy: "Created By",
    createdByEmail: "Created by email",

    skippedDates: "Skipped Dates",
    hasSkippedDates: "Yes",
    noSkippedDates: "None",

    imageAttachments: "Image Attachments",
    otherAttachments: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",
    activityLog: "Activity Log",
  },
  tasks: {
    minimizedTitle: "Task #{{index}}",
    filterTitle: "Filter Tasks",

    title: "Title",
    id: "ID",
    status: "Status",

    taskType: "Task Type",
    taskTypePlaceholder: "Select task types",
    category: "Category",

    building: "Building",
    floor: "Floor",
    suite: "Suite",

    startDate: "Start Date",
    dueDate: "Due Date",
    dueDatePlaceholder: "Select due date",
    dateCreated: "Date Created",
    createdAt: "Created At",
    completedAt: "Completed At",
    completedAtPlaceholder: "Select completed at",
    estimatedLabor: "Est. Labor",

    active: "Active",
    completed: "Completed",
    complete: "Complete",
    overdue: "Overdue",
    notCompleted: "Not Completed",
    completeTask: "Complete Task",
    uncompleteTask: "Uncomplete Task",

    createdBy: "Created By",
    createdByEmail: "Created by email",
    createdByPlaceholder: "Select created by",
    assignedTo: "Assigned To",
    assignedToEmail: "Assigned to email",
    assignedToPhone: "Assigned to phone",
    assignedToPlaceholder: "Select assignee",

    watchers: "Watchers",
    watchersPreview: "{{count}} users watching",

    proceduresTitle: "Procedures",
    addProcedureTitle: "Add Procedure",
    procedure: "Procedure",
    procedurePlaceholder: "Select procedure",
    procedureRequired: "Procedure is required",
    noProcedures: "This task does not have any procedure. Please add one.",
    procedureNotes: "Procedure Notes",
    noProcedureNotes: "This procedure does not have any notes",
    expandProcedure: "Expand procedure.",
    collapseProcedure: "Collapse procedure.",
    openProcedureInNewTab: "Open procedure in new tab.",
    removeProcedure: "Remove this procedure and its sections/steps.",

    addEquipmentTitle: "Add Equipment",
    equipment: "Equipment",
    equipmentPlaceholder: "Select equipment",
    equipmentRequired: "Equipment is required",

    noSections: "This procedure does not have any section",
    expandSection: "Expand this section.",
    collapseSection: "Collapse this section.",
    noSteps: "This section does not have any steps.",

    notes: "Notes",
    notesPlaceholder: "Enter notes",
    noNotes: "No notes",

    charges: "Charges",
    generateWorkOrder: "Generate Work Order",
    newWorkOrderTaskId: "Task ID: #{{index}}",
    newWorkOrderTaskName: "Task Name: {{title}}",
    newWorkOrderProcedureName: "Procedure Name: {{title}}",
    newWorkOrderSectionName: "Section Name: {{title}}",
    newWorkOrderStepName: "Step: {{title}}",
    scheduleName: "Schedule Name",
    customIdTooltip: "Custom ID",
    addCustomIdTooltip: "Click to add Custom ID",
    updateCustomIdTooltip: "Click to update Custom ID",

    // Custom ID Modal
    newCustomIdTitle: "Add Custom ID",
    editCustomIdTitle: "Edit Custom ID",
    customId: "Custom ID",
    customIdDescription:
      "Custom ID is a unique identifier for a step. By adding Custom ID, you can mark, specify or group step(s). If you want, you can completely remove Custom ID too.",
    customIdPlaceholder: "Enter custom ID",
    customIdError: "Custom ID is required",

    attachments: "Attachments",
    imageAttachments: "Image Attachments",
    otherAttachments: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",
    activityLog: "Activity Log",
  },
  reporting: {
    typeStep: "Type",
    filtersStep: "Filters",
    emailsStep: "Emails",

    pending: "Pending",
    ready: "Ready",
    error: "Error",

    createNewReportTitle: "Create new report",

    dateRange: "Date Range",
    visibility: "Visibility",
    status: "Status",
    dateCreated: "Date created",
    link: "Link",

    reportType: "Report type",
    reportTypePlaceholder: "Select report type",
    reportTypeError: "Report type is required",

    reportDate: "Report date",
    reportDateError: "Please select valid report date",

    endTime: "End Time",
    endTimeError: "Please select valid end time",

    reportCharge: "Charges",
    reportChargePlaceholder: "Select report charge",
    reportChargeError: "Report charge is required",

    filterByCompany: "Filter by company (Optional)",
    filterByCompanyPlaceholder: "Select companies",

    privateReport: "Private report",
    showZeroAmount: "Show zero amount Work Orders",
    sendReportInEmail: "Send report in email (Optional)",

    pmCompletionSummaryReportType: "PM Completion Summary Report",
    rentStatementBackupType: "Rent Statement Backup",
    workOrderSummaryByTenantType: "Work Order Summary by Tenant",
    workOrderJournalExtractType: "Work Order Journal Extract",
    workOrderJournalExtractGroupedType:
      "Work Order Journal Extract Grouped by Work Order",
    toastFileNotReadyYetError: "There is no file yet. Please try again later..",

    allReportsTitle: "All Reports",
    reportChargesAll: "All",
    reportChargesBillable: "Billable",
    reportChargesNonBillable: "Non billable",
    private: "Private",
    public: "Public",
    generating: "Generating..",
  },
  repositoryFiles: {
    allFilesTitle: "All Files",
    addFolderTitle: "Add Folder",
    editFolderTitle: "Edit Folder",
    folderAddedToast: "Folder added!",
    folderUpdatedToast: "Folder updated!",
    name: "Name",
    namePlaceholder: "Enter name",
    nameError: "Name is required",

    isPrivate: "Is this folder private?",
    toastSelectFileError:
      "Please first select the file/folder you want to open.",
    toastOpenEditOnlyOneFileError:
      "It is possible to open/edit only one file/folder at once.",
    toastFileHelperError:
      "Sorry, we could not open this file. Please try again..",
    toastSelectFolderError: "Please first select the folder you want to edit.",
    toastEditOnlyFoldersError: "It is possible to edit folders only.",
    toastSelectFileDownloadError:
      "Please first select the file you want to download.",
  },
  resetPassword: {
    header: "Change your password",
    description: "Create new password below",
    confirmPassword: "Confirm Password",
    rememberedPasswordButton: "Remembered your password?",
    signInButton: "Sign in!",
    newPassword: "New Password",
    newPasswordPlaceholder: "Enter new password",
    newPasswordError: "Password is required - should be 6 chars minimum.",
    passwordConfirmation: "Repeat password",
    passwordConfirmationPlaceholder: "Type the new password again",
    passwordConfirmationError: "Passwords must match",
    passwordSetSuccessToast: "Success! Your new password is all set!",
  },
  stackingPlan: {
    vacantTitle: "Vacant",
    allFloorsAndSuitesPlaceholder: "All floors and suites",
    leaseExpirationLabel: "Lease Expiration",
    company: "Company",
    leaseDate: "Lease Date",
    floorPlan: "Floor Plan",
    floorSize: "sq ft",
    suitePlan: "Suite Plan",
    suiteSize: "sq ft",

    toastDateRequired: "Date is required!",
    pleaseSelectBuildingTitle: "Please select the building first.",
    pleaseSelectBuildingDescription:
      "Please select the building first. You can find building selector in Topbar above.",
  },
  watchlist: {
    allTitle: "All Watchlist",
    newTitle: "Create a watchlist",
    minimizedTitle: "Watchlist - {{index}}",

    firstName: "First Name",
    firstNamePlaceholder: "Enter first name",
    firstNameError: "First name is required",

    lastName: "Last Name",
    lastNamePlaceholder: "Enter last name",
    lastNameError: "Last name is required",

    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",

    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",

    details: "Details",
    detailsPlaceholder: "Enter watchlist details",
    noDetailsDefault: "No details",

    dateCreated: "Date Created",

    image: "Image",
    attachments: "Attachments",

    id: "ID",
    user: "User",

    filterTitle: "Filter Watchlist",
  },
  widgets: {
    filterTitle: "Filters",
    adjustFilters: "Adjust filters",
    noDataTitle: "No data",
    noDataDescription: "Currently there is no data for selected filters.",
    date: "Date",
    selectDate: "Please select date..",
    total: "Total",
    rating: "Rating",

    building: "Building",
    buildingPlaceholder: "Select buildings",
    company: "Company",
    companyPlaceholder: "Select company",
    facility: "Facility",
    facilityPlaceholder: "Select facility",
    facilityRequired: "Facility is required. Please select one.",

    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",

    pending: "Pending",
    compliant: "Compliant",
    expired: "Expired",
    notCompliant: "Not Compliant",

    coiExpirationTitle: "COI Expiration",
    occupancyTitle: "Occupancy",
    propertyPopulationTitle: "Property Population",
    taskOpenTitle: "Open Tasks",
    taskOverdueTitle: "Overdue Tasks",
    workOrdersIssueTypesTitle: "Work Orders By Issue Type",
    workOrdersStatusesTitle: "Work Orders By Status",
    workOrdersSatisfactionTitle: "Work Order Satisfaction",
    workOrdersReviewsTitle: "Work Orders Reviews",
    workOrdersRevenueTitle: "Work Orders Revenue",

    contractChartTitle: "Contract",
    transientChartTitle: "Transient",
    vacantChartTitle: "Vacant",

    labor: "Labor",
    material: "Material",
    markup: "Markup",
  },
  users: {
    activeStatus: "Active",
    deactivatedStatus: "Deactivated",
    deletedStatus: "Deleted",

    // Genea Cards

    expiredStatus: "Expired",
    pendingActivationStatus: "Pending Activation",
    pendingDownloadStatus: "Pending Download",
    indefinitelyLabel: "Indefinitely",
    neverLabel: "Never",
    keycardLabel: "Keycard (Physical)",
    mobileLabel: "Mobile",

    type: "Type",
    typePlaceholder: "Select type",
    typeError: "Type is required",

    cardNumber: "Card Number",
    cardNumberPlaceholder: "Enter card number",
    cardNumberError: "Card number is required",

    badgeType: "Badge Type",
    badgeTypePlaceholder: "Select badge type",
    badgeTypeError: "Badge type is required",

    activationTime: "Activation Time",
    deactivationTime: "Deactivation Time",
    activationTimeError: "Activation time is required",

    validityColumnHeader: "Validity",
    lastUsedColumnHeader: "Last Used",

    // Building and Company Access Options

    includeAccessOption: "Include",
    onlyAccessOption: "Only",
    excludeAccessOption: "Exclude",

    // User Related

    informationViewLabel: "Basic Information",
    securityViewLabel: "Security",
    geneaCardsViewLabel: "Genea Cards",
    walletViewLabel: "Wallet",
    preferencesViewLabel: "Preferences",
    notificationsViewLabel: "Notifications",

    resendEmailAction: "Resend welcome email",

    addNewUserTitle: "Add new user",
    addNewGeneaCardTitle: "Add new Genea card",

    name: "Name",
    roleAssigned: "Role Assigned",

    status: "Status",
    statusPlaceholder: "Select status",
    statusError: "Status is required",

    printerLabel: "Printer",
    printerDescription: "Default printer",
    printerPlaceholder: "Select printer",
    printerTooltip:
      "If no printer is selected user will be able to use any printer manually, that is locally connected.",

    homepageLabel: "Homepage",
    homepagePlaceholder: "Select page",
    homepageDescription: "Default homepage",

    buildingsCount: "{{count}} buildings",
    buildings: "Buildings",
    buildingsDescription: "All buildings user has access to",
    buildingsPlaceholder: "Select buldings",
    buildingError: "Building is required",

    allBuildings: "All buildings",
    allBuildingsAccess: "Has access to all buildings inside property?",
    allBuildingsAccessDescription: "Access to all current and future buildings",

    primaryBuilding: "Primary Building",
    primaryBuildingPlaceholder: "Select primary building",
    primaryBuildingDescription: "Primary building of this user",

    email: "Email",
    emailPlaceholder: "Enter email",
    emailError: "Please enter valid email",

    firstName: "First Name",
    firstNamePlaceholder: "Enter first name",
    firstNameError: "First name is required",

    lastName: "Last Name",
    lastNamePlaceholder: "Enter last name",
    lastNameError: "Last name is required",

    smsColumnHeader: "SMS",
    appColumnHeader: "App",

    companies: "Companies",
    companiesDescription: "All companies user has access to",
    companiesPlaceholder: "Select companies",
    companiesError: "Company is required",

    allCompanies: "All companies",
    allCompaniesAccess: "Has access to all companies inside property?",
    allCompaniesAccessDescription: "Access to all current and future companies",

    primaryCompany: "Primary Company",
    primaryCompanyDescription: "Primary company of this user",
    primaryCompanyPlaceholder: "Select primary company",

    role: "Role",
    rolePlaceholder: "Select role",
    roleError: "Role is required",

    lastLoginLabel: "Last Login",
    lastLoginDescription: "Time when user last logged in",
    lastLoginNotRecorded: "Login activity not recorded",

    email2FaLabel: "2FA by Email",
    email2FaDescription: "Securely login by verifying email",

    phone2FaLabel: "2FA by Phone",
    phone2FaDescription: "Securely login by verifying phone",

    cardsLabel: "Cards",
    cardsDescription: "All user cards",
    addNewCardButton: "Add new card",

    title: "Title",
    titlePlaceholder: "Enter title (Engineer, Manager..)",
    titleDescription: "User's title",

    mobileNumber: "Mobile Number",
    mobileNumberPlaceholder: "No mobile number",
    phoneRemovalTitle: "Phone removal",
    phoneRemovalDescription:
      "This action will remove the phone and any other Phone 2FA associated with it? Are you sure you want to proceed?",

    userPhoto: "User photo",
    userPhotoPlaceholderDescription: "Supported formats:",

    // Filters

    filterTitle: "Filter Users",
    fullAccessCompanyUsers: "Full Company Access Users",
    fullAccessCompanyUsersTooltip:
      "You can choose to include, exclude or select only users that have full access to all companies.",
    fullAccessBuildingUsers: "Full Building Access Users",
    fullAccessBuildingUsersTooltip:
      "You can choose to include, exclude or select only users that have full access to all buildings.",

    toastNoAvatarError: "This user does not have an avatar.",
    toastStatusBulkSelfError: "You can not change status for your own profile.",
    toastUserAlreadyExist:
      "User with entered email already exist in the property.",
    toastUserAlreadyExistDescription:
      "User ({{name}}) with requested email already exist in portfolio. You can choosee to add it in this property or go back and enter different email.",
    toastOwnProfileChange:
      "Success! We detected that you've changed your own profile (buildings, companies, roles). We will refresh the page so we keep the latest data.",
    toastHomepageChange:
      "Success! We detected that you've changed your homepage. We will refresh the page so we keep the latest data.",
    disabledOptionError:
      "You can not enable this option as it is disabled in your property.",
  },
  workOrders: {
    id: "ID",
    tableDatepickerPlaceholder: "Filter by Start Date",
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required",
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    floor: "Floor",
    floorPlaceholder: "Select floor",
    suite: "Suite",
    suitePlaceholder: "Select suite",
    status: "Status",
    statusPlaceholder: "Select status",
    priority: "Priority",
    priorityPlaceholder: "Select priority",
    details: "Details",
    detailsPlaceholder: "Enter incident details",
    noDetailsDefault: "No details",
    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",
    issueTypeError: "Issue type is required",
    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",
    requestedByEmail: "Requested by email",
    requestedByCompany: "Requested by company",
    assignedTo: "Assigned To",
    assignedToPlaceholder: "Select assignee",
    createdBy: "Created By",
    createdByGuest: "Guest: {{name}}",
    dateCreated: "Date Created",
    startDate: "Start Date",
    dueDate: "Due Date",
    closedAt: "Closed At",
    closedAtPlaceholder: "Select closed at date..",
    rating: "Rating",
    noRatingComment: "No comment was given.",
    addRating: "Add rating",
    noRating: "No rating",
    attachments: "Attachments",
    imageAttachments: "Image Attachments",
    otherAttachments: "Other Attachments",
    noImages: "No images",
    noOtherAttachments: "No other attachments",
    filterTitle: "Filter Work Orders",
    filterBuildingPlaceholder: "All buildings",
    minimizedTitle: "Work Order - {{index}}",
    newTitle: "Create a work order",
    watchersButton: "Watchers",
    watchersPreview: "{{count}} users watching",
    customFieldsTitle: "Additional Information",
    activityLogTitle: "Activity Log",
    chargesTitle: "Charges",
    noPermissionsCharges:
      "You do not have required permissions to see charges.",
    equipmentTitle: "Equipment",
    acknowledgeButton: "Acknowledge",
    readyForReview: "Your work order is ready for review. Click to add review.",
    generatedByTemplateTooltip:
      "This Work Order has been generated by Work Order Template - #{{index}}. Click to view.",

    // New Equipment
    addEquipmentTitle: "Add Equipment",
    equipmentLabel: "Equipment",
    equipmentPlaceholder: "Select equipment",
    equipmentError: "Equipment is required",
  },
  workOrderTemplates: {
    id: "ID",
    tableDatepickerPlaceholder: "Filter by Next Date",
    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required",
    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",
    floor: "Floor",
    floorPlaceholder: "Select floor",
    suite: "Suite",
    suitePlaceholder: "Select suite",
    status: "Status",
    statusPlaceholder: "Select status",
    priority: "Priority",
    priorityPlaceholder: "Select priority",
    details: "Details",
    detailsPlaceholder: "Enter incident details",
    noDetailsDefault: "No details",
    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",
    issueTypeError: "Issue type is required",
    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requester is required",
    requestedByEmail: "Requested by email",
    requestedByCompany: "Requested by company",
    assignedTo: "Assigned To",
    assignedToPlaceholder: "Select assignee",
    createdBy: "Created By",
    dateCreated: "Date Created",
    startDate: "Start Date",
    previousDate: "Previous Date",
    noPreviousDate: "No previous date",
    nextDate: "Next Date",
    noNextDate: "No next date",
    skippedDates: "Skipped Dates",
    hasSkippedDates: "Yes",
    noSkippedDates: "None",
    dueAfterDays: "Due After",
    dueAfterDaysTooltip:
      "Number of days until work order is due after generation",
    dueAfterDaysPlaceholder: "Enter number of days",
    interval: "Interval",

    attachments: "Attachments",
    filterTitle: "Filter Work Order Templates",
    filterBuildingPlaceholder: "All buildings",
    minimizedTitle: "Work Order Template - {{index}}",
    newTitle: "Create a work order template",
    watchersButton: "Watchers",
    watchersPreview: "{{count}} users watching",
    customFieldsTitle: "Additional Information",
    activityLogTitle: "Activity Log",
    chargesTitle: "Charges",
    noPermissionsCharges:
      "You do not have required permissions to see charges.",
    workOrdersTitle: "Related Records",
  },
  workOrderGuestForms: {
    subtitle: "Please fill in the form below",
    errorTitle: "An error occurred",
    notFoundTitle: "Sorry, we could not find the guest form",
    notFoundDescription: "Please try again later..",

    firstName: "First Name",
    firstNamePlaceholder: "Enter first name",
    firstNameError: "First name is required",

    lastName: "Last Name",
    lastNamePlaceholder: "Enter last name",
    lastNameError: "Last name is required",

    email: "Email",
    emailPlaceholder: "Enter email",
    emailError: "Please enter valid email",

    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",
    issueTypeError: "Issue type is required",

    status: "Status",
    statusPlaceholder: "Select status",
    statusError: "Status is required",

    priority: "Priority",
    priorityPlaceholder: "Select priority",
    priorityError: "Priority is required",

    assignee: "Assignee",
    assigneePlaceholder: "Select assignee",
    assigneeError: "Assignee is required",

    floor: "Floor",
    floorPlaceholder: "Select floor",
    floorError: "Floor is required",

    suite: "Suite",
    suitePlaceholder: "Select suite",
    suiteError: "Suite is required",

    details: "Details",
    detailsPlaceholder: "Enter work order details",

    attachments: "Attachments",
    attachmentsError: "Attachment is required",

    // Success messages
    successTitle: "Success!",
    successDescription: "You have successfully submitted work order.",

    // Sign in link
    signInText: "Have an account?",
    signInButton: "Sign in!",
  },
  adminARCodes: {
    arCodeTitle: "AR Code", // used on table header and view in topbar
    editArCodeTitle: "Edit AR Code",
    addArCodeTitle: "Add AR Code",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
  },
  adminChargeCodes: {
    chargeCodeTitle: "Charge Code", // used on table header and view in topbar
    editChargeCodeTitle: "Edit Charge Code",
    addChargeCodeTitle: "Add Charge Code",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
  },
  adminGLAccounts: {
    glTitle: "GL Account", // used on table header and view in topbar
    editGLAccountTitle: "Edit GL Account",
    addGLAccountTitle: "Add GL Account",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
  },
  adminMarkups: {
    markupTitle: "Markup", // used on table header and view in topbar
    editMarkupTitle: "Edit Markup",
    addMarkupTitle: "Add Markup",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
    description: "Markup Details",
    descriptionPlaceholder: "Enter details",
    type: "Type",
    typePlaceholder: "Select type",
    typeError: "Type is required",
    amount: "Amount",
    amountPlaceholder: "Enter amount",
    glAccount: "GL Account",
    glAccountPlaceholder: "Select GL Account",
    arCode: "AR Code",
    arCodePlaceholder: "Select AR Code",
    chargeCode: "Charge Code",
    chargeCodePlaceholder: "Select Charge Code",
    normalType: "Normal ($)",
    percentType: "Percent (%)",
  },
  adminTaxes: {
    taxTitle: "Tax", // used on table header and view in topbar
    newTaxTitle: "Add new tax",
    editTaxTitle: "Edit tax",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
    amount: "Amount",
    amountPlaceholder: "Enter amount",
    glAccount: "GL Account",
    glAccountPlaceholder: "Select GL Account",
    arCode: "AR Code",
    arCodePlaceholder: "Select AR Code",
    chargeCode: "Charge Code",
    chargeCodePlaceholder: "Select Charge Code",
    statusActive: "Active",
    statusInactive: "Inactive",
  },
  adminActivityLog: {
    featureTitle: "Title",
    tableTitle: "All activity Log",
    filterTitle: "Filter Activity Log",
    activities: "{{count}} activities",
    noActivityData: "No activity",
    comment: "Comment",
    user: "User",
    userPlaceholder: "Select user",
    type: "Type",
    activity: "Activity",
    author: "Author",
    feature: "Feature",
    date: "Date",

    coiFeatureTitle: "COI",
    companyFeatureTitle: "Company",
    dealFeatureTitle: "Deal",
    equipmentFeatureTitle: "Equipment",
    guestFeatureTitle: "Guest",
    incidentFeatureTitle: "Incident",
    leaseFeatureTitle: "Lease",
    meetingHubFeatureTitle: "Meeting Hub",
    procedureFeatureTitle: "Procedure",
    scheduleFeatureTitle: "Schedule",
    subscriptionFeatureTitle: "Subscription",
    suiteFeatureTitle: "Suite",
    taskFeatureTitle: "Task",
    vendorAccessFeatureTitle: "Vendor Access",
    watchlistFeatureTitle: "Watchlist",
    workOrderFeatureTitle: "Work Order",
    workOrderTemplateFeatureTitle: "Work Order Template",
  },
  adminAmanoSettings: {
    tableTitle: "Amano Settings",

    editAmanoSettingTitle: "Edit Amano Setting",
    addAmanoSettingTitle: "Add Amano Setting",

    id: "ID",

    product: "Product",
    productPlaceholder: "Select product",
    productError: "Product is required",

    facility: "Facility",
    facilityPlaceholder: "Select facility",
    facilityError: "Facility is required",

    accessGroups: "Access Groups",
    accessGroupsPlaceholder: "Select access group",
    accessGroupsError: "Access group is required",
  },
  adminBuildings: {
    editBuildingTitle: "Edit Building",
    addBuildingTitle: "Add Building",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",
  },
  adminPredefinedBroadcasts: {
    editTitle: "Edit Predefined Broadcast",
    addTitle: "Add Predefined Broadcast",
    subject: "Subject",
    subjectPlaceholder: "Enter subject",
    subjectError: "Subject is required",
    body: "Body",
    bodyTooltip: "Used for SMS or APP broadcasts",
    bodyPlaceholder: "Enter body",
    emailBody: "Email Body",
    emailBodyPlaceholder: "Enter email body",
    toastFieldsRequired: "Body or Email Body is required!",
  },
  adminPropertyAddons: {
    tableTitle: "Property Addons",
    editTitle: "Edit Property Addon",
    addTitle: "Add Property Addon",
    key: "Key",
    keyPlaceholder: "Enter key",
    keyError: "Key is required",
    title: "Title",
    titleError: "Title is required",
    icon: "Icon",
    iconError: "Icon is required",
    value: "Text",
    valueError: "Text is required",
    workingHours: "Working Hours",
    email: "Email",
    phone: "Phone",
    primaryContact: "Primary Contact",
    other: "Other",
  },
  adminCoupons: {
    tableTitle: "Coupons",

    editCouponTitle: "Edit Coupon",
    addCouponTitle: "Add Coupon",

    durationOnce: "Once",
    durationForever: "Forever",
    durationRepeating: "Repeating",

    stripeAccount: "Stripe Account",
    stripeAccountTooltip: "Stripe account that this coupon is linked to.",
    stripeAccountError: "Stripe account is required",

    discountTypeAmount: "Amount",
    discountTypePercent: "Percent",

    codeColumnHeader: "Code",
    createdAtColumnHeader: "Created At",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    duration: "Duration",
    durationPlaceholder: "Select duration",
    durationError: "Duration is required",

    durationInMonths: "Valid for how many months?",
    durationInMonthsPlaceholder: "Enter months",
    durationInMonthsError: "Months are required",

    discountType: "Discount Type",
    discountTypePlaceholder: "Select discount type",
    discountTypeError: "Discount type is required",

    discountAmount: "Discount Amount",
    discountAmountPlaceholder: "Enter discount amount",

    products: "Products",
    productsPlaceholder: "Select products this coupon applies to",
  },
  adminCustomFields: {
    workOrderView: "Work Orders",
    incidentView: "Incidents",
    meetingHubView: "Meeting Hub",
    coiView: "COI",

    inputOption: "Input",
    dropdownOption: "Dropdown",
    checkboxOption: "Checkbox",
    singleDatepickerOption: "Single Datepicker",
    rangeDatepickerOption: "Range Datepicker",

    textOption: "Text",
    colorOption: "Color",
    numberOption: "Number",
    emailOption: "Email",

    addNewCustomFieldTitle: "Add new custom field",
    customKeysValidationError:
      "Custom keys can only contain letters and numbers.",
    editCustomFieldTitle: "Edit custom field",
    noViewSelected: "Please select the view first.",
    noViewSelectedDescription: "You can find view selector in Topbar above.",

    deleteConfirmationTitle: "Delete Confirmation",
    continueAction: "Continue",
    deleteConfirmationDescription:
      "This action will also delete data tied to the selected {{count}} field(s) in existing records. Are you sure you want to continue?",

    activationConfirmationTitle: "Activation Confirmation",
    deactivationConfirmationTitle: "Deactivation Confirmation",

    activationConfirmationDescription:
      "Do you also want to reactivate data tied to the selected field in existing records?",
    deactivationConfirmationDescription:
      "Do you also want to deactivate (hide) data tied to the selected field in existing records?",

    orderTableHeader: "Order",

    key: "Key",
    keyPlaceholder: "Enter key",
    keyTooltip:
      "Value of this field will be used as variable in Email Templates. Changing this field will also require updating Email Template(s) which use this field currently. Field should not have any spaces or special characters. Valid examples: dueDate, expiresIn",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleTooltip: "Value of this field will be used as label/title for user.",
    titleError: "Title is required",
    unknownType: "Unknown Type - {{type}}",

    fieldType: "Field Type",
    fieldTypeTooltip:
      "Type is not editable. You can create new custom field, deactivate or delete this custom field if you wish to change the type.",
    fieldTypePlaceholder: "Select field type",
    fieldTypeError: "Field type is required",

    inputTypeLabel: "Input Type",
    inputTypePlaceholder: "Select input type",
    inputTypeTooltip:
      "Default is Text. You can choose other if you need to accept email or number.",
    inputTypeError: "Input type is required",

    dropdownItemsLabel: "Dropdown Items",
    dropdownItemsPlaceholder: "Create dropdown items",
    dropdownStartTyping: "Start typing to create new item..",
    dropdownItemsError: "Dropdown items are required",

    dropdownTypeLabel: "Dropdown Type",
    dropdownTypePlaceholder: "Select dropdown type",
    dropdownTypeTooltip:
      "Default is Text. If you choose Color type, please add items/options as hex. For example: #E7F5FF, #1864AB",

    dropdownMultiCheckbox: "Multi select?",
    dropdownMultiCheckboxTooltip:
      "By default this field will allow selecting one option. If you want to enable multi select, please check this option.",

    rangeDatepickerSeparateCheckbox: "Display two separate datepickers?",
    rangeDatepickerSeparateCheckboxTooltip:
      "By default this field will be displayed as one datepicker. If you check this option this field will be displayed as two separate datepickers (from/to).",

    requiredCheckbox: "Required on creation?",
    requiredCheckboxTooltip:
      "Is this custom field required to fill when creating new record?",

    addToExistingRecordsCheckbox: "Add to existing records?",
    addToExistingRecordsCheckboxTooltip:
      "By default, new field will be available when creating new records. If you want to add this field to existing records, please check this field.",

    toastToggleError: "Please first select record you want to toggle.",
  },
  adminDashboard: {
    pageTitle: "General Property Settings",
    toastDataChanged:
      "Success! We have detected that some of main data has been changed so page will now refresh to take the effect of the changes you made.",

    propertyNameLabel: "Property name",
    propertyNameDescription: "Actual name of the property",
    propertyNamePlaceholder: "Enter property name",
    propertyNameError: "Property name is required",

    timezoneLabel: "Timezone",
    timezoneDescription: "Timezone of the property",
    timezonePlaceholder: "Select timezone",
    timezoneError: "Timezone is required",

    primaryColorLabel: "Primary color",
    primaryColorDescription: "Primary color of the property",
    primaryColorPlaceholder: "Select primary color",

    propertyLogoLabel: "Property logo",
    propertyLogoDescription: "Add a logo image for your property",

    propertyBgImageLabel: "Background image",
    propertyBgImageDescription: "Background image used on the log in screen",

    faviconLabel: "Favicon",
    faviconDescription: "Icon displayed in the address bar of a browser",

    addressLabel: "Address",
    addressDescription: "Address of the property",
    addressPlaceholder: "Enter address",

    longitudeLabel: "Longitude",
    longitudeDescription: "Longitude of the property",
    longitudePlaceholder: "Enter longitude",

    latitudeLabel: "Latitude",
    latitudeDescription: "Latitude of the property",
    latitudePlaceholder: "Enter latitude",
  },
  adminDevices: {
    pageTitle: "Devices",

    editDeviceTitle: "Edit Device",
    addDeviceTitle: "Add Device",

    appVersionTableHeader: "App Version",
    osVersionTableHeader: "OS Version",
    isPairedTableHeader: "Paired?",
    isPrimaryTableHeader: "Primary?",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    printer: "Printer",
    printerPlaceholder: "Select printer",
    printerError: "Printer is required",

    deviceLogsPageTitle: "All Waivers",

    viewLogAction: "View Log",
    setAsPrimaryAction: "Set as Primary",
    pairDeviceAction: "Pair device",
    pairedButton: "Paired",

    deviceLogsMessageTableHeader: "Message",
    deviceLogsTypeTableHeader: "Type",
    deviceLogsLocationTableHeader: "Location",
  },
  adminEmployees: {
    editEmployeeTitle: "Edit Employee",
    addEmployeeTitle: "Add Employee",
    tableTitle: "All Employees",

    rateTableHeader: "Rate",
    laborRateTableHeader: "Labor Rate",
    glAccountTableHeader: "GL Account",
    arCodeTableHeader: "AR Code",
    chargeCodeTableHeader: "Charge Code",
    markupTableHeader: "Markup",
    taxTableHeader: "Tax",
    assignableTableHeader: "Assignable?",

    user: "User",
    userPlaceholder: "Select user",
    userError: "User is required",

    laborRate: "Labor Rate",
    laborRatePlaceholder: "Select labor rate",

    tax: "Tax",
    taxPlaceholder: "Select tax",
  },
  adminGeneaSettings: {
    geneaSettingsTitle: "Genea Settings",

    productTableHeader: "Property Product",

    accessGroupTableHeader: "Genea Access Group",
    accessGroupPlaceholder: "Select access group",

    productAccessGroupMappingTitle: "Genea Product/Access Group Mapping",
    productAccessGroupMappingTooltip:
      "Connect your Building Hub products with specific Genea Access Groups.",

    companyTableHeader: "Building Hub Company",
    tenantTableHeader: "Genea Company",

    tenantPlaceholder: "Select tenant",
    tenantMappingTitle: "Genea Tenant Mapping",
    tenantMappingTooltip:
      "In order for users to be created on Genea, a tenant in BuildingHub must be mapped to a tenant in Genea.",

    companyTypePlaceholder: "Filter company type",

    emailLabel: "Email",
    emailDescription: "Email of master visitor",
    emailPlaceholder: "Enter email",

    badgeTypeLabel: "Badge Type",
    badgeTypeDescription: "Badge type",
    badgeTypePlaceholder: "Select badge type",

    badgeTypeRequiredError: "Badge type is required",
  },
  adminStripeSettings: {
    title: "Stripe Settings",

    cardFee: "Card Fee",
    cardFeeDescription: "Card fee for the property",
    cardFeePlaceholder: "Enter fee",
  },
  adminGuestSettings: {
    guestSettingsTitle: "Guest Settings",
    badgeFormatGuestID: "GuestID",
    badgeFormatJulianDate: "Julian Date",

    badgeLogoLabel: "Badge Logo",
    badgeLogoDescription: "Add a badge logo for your property",

    printQRCodeLabel: "Print guest QR code on badge",
    printQRCodeDescription:
      "When enabled, the guest QR code will print on the visitor badge.",

    printQRCodeCheckboxLabel: "Print guest QR code on badge",

    badgeFormatLabel: "Badge Format",
    badgeFormatDescription: "Choose badge format. Default is GuestID.",
    badgeFormatPlaceholder: "Select badge format",
  },
  adminIntegrations: {
    integrationTitle: "Integration",

    integrationGenea: "Genea",
    integrationGeneaDescription: "Manage security in the property",

    integrationIntercom: "Intercom",
    integrationIntercomDescription:
      "Build better customer relationships through conversational experiences",

    integrationAmano: "Amano",
    integrationAmanoDescription:
      "Enable parking integration for property users",

    integrationEmailSms: "Email/SMS",
    integrationMailgunDescription:
      "Send, receive, and track emails using Mailgun",
    integrationTwilioDescription:
      "Personalized interactions and trusted global communications with customers",

    integrationCloudPrinting: "Cloud Printing",
    integrationCloudPrintingDescription:
      "Connect any printer to your application with the BuildingHub Cloud Printing",

    integrationUnknown: "Unknown",
    integrationUnknownDescription: "Unknown integration",

    integrationManageButton: "Manage",
    integrationTestButton: "Test",

    integrationCreateAccountButton: "Create an account",
    integrationDontHaveAccountTitle: "Don't have an account?",
    integrationDontHaveAccountDescription:
      "If you dont't have an account please feel free to open one.",

    integrationEnterDataPlaceholder: "Enter data",
    integrationEnterDataError: "Please insert valid data",

    toastIntegrationAddIntegrationData: "Please add integration data first.",
    toastIntegrationTestSuccess:
      "Looks like everything is fine with the data you provided!",
    toastIntegrationTestError:
      "Looks like something is wrong with the data you provided!",
    toastNoIntegrationsTitleError: "No integrations",
    toastNoIntegrationsDescriptionError:
      "No integrations available in this moment..",
  },
  adminKiosk: {
    cameraTypeBack: "Back",
    cameraTypeFront: "Front",

    appearanceTitle: "Appearance",
    backgroundImageLabel: "Background Image",
    backgroundImageDescription: "Background image used on kiosk app homescreen",

    primaryColorLabel: "Primary Color",
    primaryColorDescription: "Primary color of the kiosk app",
    primaryColorPlaceholder: "Solid color",

    welcomeTextLabel: "Welcome Text",
    welcomeTextDescription: "Welcome text for the kiosk app",
    welcomeTextPlaceholder: "Enter welcome text",

    welcomeSubtitleLabel: "Subtitle",
    welcomeSubtitleDescription: "Subtitle for the kiosk app",
    welcomeSubtitlePlaceholder: "Enter subtitle",

    identificationTitle: "Identification",

    qrCodeCheckInLabel: "QR code",
    qrCodeCheckInDescription: "Allow visitors to check in with QR code",
    qrCodeCheckInCheckboxLabel: "Visitors Can Check In With QR code",

    locatorCheckInLabel: "Record locator",
    locatorCheckInDescription: "Allow visitors to check in with Record locator",
    locatorCheckInCheckboxLabel: "Visitors Can Check In With Record Locator",

    cameraSettingsTitle: "Camera Settings",

    qrCodeScanningCameraLabel: "QR Code Scanning Camera",
    qrCodeScanningCameraDescription:
      "Select which camera will be used for scanning QR code",
    qrCodeScanningCameraPlaceholder: "Front or back side",

    qrCodeScanningCameraFlipLabel: "QR code scanning camera flip",
    qrCodeScanningCameraFlipCheckboxLabel: "Mirror camera image horizontally",

    selfieScanningCameraLabel: "Selfie scanning camera",
    selfieScanningCameraDescription:
      "Select which camera will be used for taking pictures",
    selfieScanningCameraPlaceholder: "Front or back side",

    selfieScanningCameraFlipLabel: "Selfie scanning camera flip",
    selfieScanningCameraFlipCheckboxLabel: "Mirror camera image horizontally",
  },
  adminLaborRates: {
    addNewLaborRateTitle: "Add new labor rate",
    editLaborRateTitle: "Edit labor rate",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleRequired: "Title is required",

    description: "Description",
    descriptionPlaceholder: "Enter description",

    markup: "Markup",
    markupPlaceholder: "Select markup",

    glAccount: "GL Account",
    glAccountPlaceholder: "Select GL Account",

    arCode: "AR Code",
    arCodePlaceholder: "Select AR Code",

    chargeCode: "Charge Code",
    chargeCodePlaceholder: "Select Charge Code",

    rate: "Rate",
    ratePlaceholder: "Enter rate per hour",
  },

  adminLeasingChargeTypes: {
    addNewChargeTypeTitle: "Add new charge type",
    editChargeTypeTitle: "Edit charge type",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleRequired: "Title is required",
  },
  adminMaterialGroups: {
    allMaterialGroupsTitle: "All Material Groups",

    addNewMaterialGroupTitle: "Add new material group",
    editMaterialGroupTitle: "Edit material group",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleRequired: "Title is required",

    countTableHeader: "Count",
  },
  adminMaterials: {
    allMaterialsTitle: "All Materials",

    addNewMaterialTitle: "Add new material",
    editMaterialTitle: "Edit material",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleRequired: "Title is required",

    group: "Group",
    groupPlaceholder: "Select group",
    groupRequired: "Group is required",

    description: "Description",
    descriptionPlaceholder: "Enter description",

    markup: "Default Markup",
    markupPlaceholder: "Select markup",

    tax: "Tax",
    taxPlaceholder: "Select tax",

    glAccount: "GL Account",
    glAccountPlaceholder: "Select GL Account",

    arCode: "AR Code",
    arCodePlaceholder: "Select AR Code",

    chargeCode: "Charge Code",
    chargeCodePlaceholder: "Select Charge Code",

    price: "Price",
    pricePlaceholder: "Enter price",
  },
  adminMenuItems: {
    addNewMenuItemTitle: "Add new menu item",
    editMenuItemTitle: "Edit menu item",

    title: "Title",
    titlePlaceholder: "Enter menu title",
    titleError: "Title is required",

    icon: "Icon",
    iconPlaceholder: "Select icon",

    type: "Type",
    typePlaceholder: "Select item type",
    typeError: "Type is required",

    sectionTitle: "Section",
    feature: "Feature",
    featurePlaceholder: "Select feature",
    featureError: "Feature is required",

    product: "Product",
    productPlaceholder: "Select product",
    productError: "Product is required",

    externalLink: "External Link",
    internalLink: "Internal Link",
    embed: "Embed",

    urlSlug: "URL Slug",

    urlDomain: "URL Domain",

    urlPlaceholder: "Enter URL",
    urlError: "URL is required",

    embedBody: "Embed Body",

    embedBodyPlaceholder: "Enter embed body",
    embedBodyError: "Embed body is required",

    example: "Example:",
    webView: "Web View",
    mobileView: "Mobile View",
  },
  adminNotifications: {
    emailTestSuccess: "Success! Please check your email.",
    defaultEmailTemplateError:
      "Sorry, you can not update default Email Template. Please make it custom first.",
    pleaseAddSubject: "Please add subject!",
    pleaseAddEmailBody: "Please add email body!",
    variableCopied:
      "Variable has been copied! You can now paste it anywhere you need it.",
    customEmailTemplateDescription: "This is custom email template.",
    defaultEmailTemplateDescription:
      "This is default email template. If you wish to update this email template, please make it custom first.",

    allNotificationsLabel: "All Notifications",

    sendByLabel: "Send by",
    selectVariable: "Select variable",
    email: "Email",
    sms: "SMS",
    app: "App",

    cc: "CC",
    ccPlaceholder: "Enter email..",
    ccNoOptionsError: "Enter email..",

    bcc: "BCC",
    bccPlaceholder: "Enter email..",
    bccNoOptionsError: "Enter email..",

    subject: "Subject",
    subjectPlaceholder: "Enter subject..",
    subjectError: "Subject is required",

    emailBody: "Email Body",
    emailBodyPlaceholder: "Enter email body",
    emailBodyError: "Body is required",

    smsBody: "SMS Body",
    smsBodyPlaceholder: "Enter sms body",
    smsBodyError: "Body is required",

    appBody: "App Body",
    appBodyPlaceholder: "Enter app body",
    appBodyError: "Body is required",

    testEmailButton: "Test Email",

    categoryTableHeader: "Category",
    actionTableHeader: "Action",
    customTemplateTableHeader: "Custom Template",
    customTemplateTooltip:
      "This is a default notification template. You can copy this to a custom notification template if you want to make changes.",

    useCustomTemplateAction: "Use Custom Template",
    updateTemplateAction: "Update Template",
    removeTemplateAction: "Remove Custom Template",
    viewTemplateAction: "View Template",

    globalVar: "Global",
    localVar: "Local",
  },
  adminPmCustomIds: {
    addTitle: "Add new custom id",
    editTitle: "Edit custom id",

    title: "Name",
    titlePlaceholder: "Enter name",
    titleError: "Name is required",
  },
  adminPmTaskTypes: {
    addTitle: "Add new task type",
    editTitle: "Edit task type",

    title: "Name",
    titlePlaceholder: "Enter name",
    titleError: "Name is required",
  },
  adminPrinters: {
    allPrintersLabel: "All Printers",

    addNewPrinterTitle: "Add new printer",
    editPrinterTitle: "Edit printer",

    title: "Printers",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    model: "Model",
    modelPlaceholder: "Select model",
    modelError: "Model is required",

    ipAddress: "IP Address",
    ipAddressPlaceholder: "Enter IP Address",
    ipAddressError: "IP Address is required",

    paperType: "Paper Type",
    paperTypePlaceholder: "Select paper type",
    paperTypeError: "Paper type is required",

    synced: "Synced",
  },
  adminReportingSettings: {
    generalReportingSettingsTitle: "General Reporting Settings",

    logo: "Logo",
    logoDescription: "Logo used on the invoice",

    address: "Address",
    addressDescription: "Your invoice footer address",
    addressPlaceholder: "Enter the invoice address",

    yardiPropertyId: "Yardi Property ID",
    yardiPropertyIdDescription: "Your Yardi (CSV) report property ID",
    yardiPropertyIdPlaceholder: "Enter property ID",

    groupedChargesGLCode: "GL Code for Grouped Charges",
    groupedChargesGLCodeDescription:
      "Billable expense income account for invoicing (for rent statements)",

    groupedChargesARCode: "AR Code for Grouped Charges",
    groupedChargesARCodeDescription:
      "Billable expense income account for invoicing (for rent statements)",
  },
  adminFloors: {
    addNewFloorTitle: "Add new floor",
    editFloorTitle: "Edit floor",

    allFloorsLabel: "All Floors",

    building: "Building",
    buildingPlaceholder: "Select building",
    buildingError: "Building is required",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    size: "Size (sf)",
    sizePlaceholder: "Enter size",
    sizeError: "Size is required",
  },
  adminStackingPlanOverview: {
    tableTitle: "Stacking Plan - Overview",
    floorType: "Floor",
    suiteType: "Suite",
  },
  adminSuites: {
    allSuites: "All Suites",
    addNewSuiteTitle: "Add new suite",
    editSuiteTitle: "Edit suite",

    tenantType: "Tenant",
    amenityType: "Amenity",
    mechanicalType: "Mechanical",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    floor: "Floor",
    floorPlaceholder: "Select floor",
    floorError: "Floor is required",

    size: "Size (sf)",
    sizePlaceholder: "Enter size",
    sizeError: "Size is required",

    type: "Type",
    typePlaceholder: "Select type",
    typeError: "Type is required",

    bomaSize: "BOMA Size",
    bomaSizePlaceholder: "Enter BOMA size",

    color: "Color",

    suitePlan: "Suite Plan",

    isPublic: "Is Public",
  },
  adminWaivers: {
    addNewWaiverTitle: "Add new waiver",
    editWaiverTitle: "Edit waiver",

    selectVariable: "Select variable",

    isActive: "Is Waiver Active",
    isPublic: "Is Waiver Public",
    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    text: "Text",
    textPlaceholder: "Enter text",
    textError: "Text is required",

    type: "Type",
    typePlaceholder: "Select type",
    typeError: "Type is required",

    createdBy: "Created By",

    isPublicTableHeader: "Public?",

    toastVariablesError:
      "Some waiver variables might not be available. Error: {{message}}",
    toastVariableCopied:
      "Variable has been copied! You can now paste it anywhere you need it.",
  },
  adminIncidentsIssueTypes: {
    editIssueTypeTitle: "Edit issue type",
    addIssueTypeTitle: "Add issue type",

    notifyUsers: "Notify Users",
    notifyUsersPlaceholder: "Select users to notify",

    defaultEmployee: "Default Employee",
    defaultEmployeePlaceholder: "Select employee",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    parentGroup: "Parent Group",
    parentGroupPlaceholder: "Select parent group",
    parentGroupError: "Parent group is required",

    defaultIncidentDetails: "Default Incident Details",
    defaultIncidentDetailsPlaceholder: "Enter details",
  },
  adminIncidentsIssueTypesGroups: {
    editIssueTypeGroupTitle: "Edit issue type group",
    addIssueTypeGroupTitle: "Add issue type group",

    issueTypesTableHeader: "Issue Types",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    icon: "Icon",
    iconPlaceholder: "Select icon",
  },
  adminIncidentsStatuses: {
    addNewStatusTitle: "Add new status",
    editStatusTitle: "Edit status",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    color: "Color",
    colorError: "Color is required",
  },
  adminWorkOrderGuestForms: {
    filterWorkOrderGuestFormsTitle: "Filter Work Order Guest Forms",

    editGuestForm: "Edit Guest Form",
    newGuestForm: "New Guest Form",

    id: "ID",
    linkTableHeader: "Link",
    createdByTableHeader: "Created By",

    copyFormAction: "Copy",
    embedFormAction: "Embed",

    detailsStep: "Details",
    settingsStep: "Settings",
    overviewStep: "Overview",

    building: "Building",
    buildingTooltip:
      "Building by is required field for work order creation. Guest can not modify this field.",
    buildingPlaceholder: "Select buildings",
    buildingError: "Building is required",

    requestedBy: "Requested By",
    requestedByTooltip:
      "Requested by is required field for work order creation. Guest can not modify this field.",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requested by is required",

    company: "Company",
    companyTooltip:
      "Company is required field for work order creation. Guest can not modify this field.",
    companyPlaceholder: "Select company",
    companyError: "Company is required",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    subTitle: "Subtitle",
    subTitlePlaceholder: "Enter subtitle",

    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",

    status: "Status",
    statusPlaceholder: "Select status",

    priority: "Priority",
    priorityPlaceholder: "Select priority",

    assignee: "Assigned To",
    assigneePlaceholder: "Select assignee",

    floor: "Floor",
    floorPlaceholder: "Select floor",

    suite: "Suite",
    suitePlaceholder: "Select suite",

    attachmentTypes: "Attachment Types",
    attachmentTypesPlaceholder: "Select attachment types",

    enableUserToSelectStatus: "Enable user to select status",
    enableUserToSelectPriority: "Enable user to select priority",
    enableUserToSelectAssignee: "Enable user to select assignee",
    enableUserToSelectFloor: "Enable user to select floor",
    enableUserToSelectSuite: "Enable user to select suite",
    enableUserToUploadAttachments: "Enable user to upload attachments",

    requiredCheckboxLabel: "Required",

    embedCode: "Embed Code",
    urlLink: "URL Link",

    successMessage: "Success! Your guest work order form is ready.",
  },
  adminWorkOrderEmails: {
    filterWorkOrderGuestFormsTitle: "Filter",

    editTitle: "Edit",
    newTitle: "New",

    id: "ID",
    email: "Email",
    createdBy: "Created By",

    copyEmailAction: "Copy Email",

    detailsStep: "Details",
    settingsStep: "Settings",
    overviewStep: "Overview",

    building: "Building",
    buildingPlaceholder: "Select buildings",
    buildingError: "Building is required",

    requestedBy: "Requested By",
    requestedByPlaceholder: "Select requester",
    requestedByError: "Requested by is required",

    company: "Company",
    companyPlaceholder: "Select company",
    companyError: "Company is required",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    emailSlug: "Email Slug",
    emailSlugPlaceholder: "Enter email slug",
    emailSlugTooltip:
      "This is the slug that will be used to send the email to. This is optional field. If empty, system will create one automatically.",

    issueType: "Issue Type",
    issueTypePlaceholder: "Select issue type",
    issueTypeError: "Issue type is required",

    status: "Status",
    statusPlaceholder: "Select status",

    priority: "Priority",
    priorityPlaceholder: "Select priority",

    assignee: "Assigned To",
    assigneePlaceholder: "Select assignee",

    floor: "Floor",
    floorPlaceholder: "Select floor",

    suite: "Suite",
    suitePlaceholder: "Select suite",

    allowUnknownSender: "Allow unknown sender?",
    allowUnknownSenderTooltip:
      "By default, system will only allow senders whose email exists in the system. If enabled, system will allow unknown sender to create work order.",

    successMessage: "Success! Your email to work order is ready to use.",
  },
  adminCoiEmails: {
    editTitle: "Edit",
    newTitle: "New",

    id: "ID",
    email: "Email",
    createdBy: "Created By",

    copyEmailAction: "Copy Email",

    detailsStep: "Details",
    settingsStep: "Settings",
    overviewStep: "Overview",

    company: "Company",
    companyError: "Company is required",

    title: "Title",
    titleError: "Title is required",

    status: "Status",
    statusError: "Status is required",

    emailSlug: "Email Slug",
    emailSlugTooltip:
      "This is the slug that will be used to send the email to. This is optional field. If empty, system will create one automatically.",

    contactEmails: "Contact Emails",
    contactEmailsPlaceholder: "Enter email..",

    allowUnknownSender: "Allow unknown sender?",
    allowUnknownSenderTooltip:
      "By default, system will only allow senders whose email exists in the system. If enabled, system will allow unknown sender to create COI.",

    successMessage: "Success! Your email to COI is ready to use.",
  },
  adminWorkOrderIssueTypes: {
    addIssueTypeTitle: "Add issue type",
    editIssueTypeTitle: "Edit issue type",

    notifyUsersLabel: "Notify Users",
    notifyUsersPlaceholder: "Select users to notify",

    employeeLabel: "Default Employee",
    employeePlaceholder: "Select employee",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    group: "Group",
    groupPlaceholder: "Select group",
    groupError: "Group is required",

    description: "Description",
    descriptionPlaceholder: "Enter description",

    priority: "Priority",
    priorityPlaceholder: "Select priority",
  },
  adminWorkOrderIssueTypesGroups: {
    addIssueTypeGroupTitle: "Add issue type group",
    editIssueTypeGroupTitle: "Edit issue type group",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    icon: "Icon",
    iconPlaceholder: "Select icon",

    issueTypesTableHeader: "Issue Types",
  },

  adminWorkOrderPriorities: {
    addNewPriorityTitle: "Add new priority",
    editPriorityTitle: "Edit priority",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    color: "Color",
    colorError: "Color is required",
  },
  adminWorkOrderStatuses: {
    addNewStatusTitle: "Add new status",
    editStatusTitle: "Edit status",

    title: "Title",
    titlePlaceholder: "Enter title",
    titleError: "Title is required",

    color: "Color",
    colorError: "Color is required",
  },
  importCois: {
    importCoisBoxTitle: "Import COIs",
    importCoisBoxDescription:
      "Efficiently manage your COIs by importing all relevant data to enhance organization and optimize space utilization",

    importCoisTitle: "Import COIs",
    coiAttachmentTitle: "COI Attachment",
    titleColumnHeader: "Title",
    companyColumnHeader: "Company",
    statusColumnHeader: "Status",
    validFromColumnHeader: "Valid From",
    validToColumnHeader: "Valid To",
    noteColumnHeader: "Note",
    contactEmailsColumnHeader: "Contact Emails",
    attachmentColumnHeader: "Attachment",

    importedCois: "Imported COIs",
    skippedCois: "Skipped COIs",

    enterEmailPlaceholder: "Enter email..",
  },

  importCompanies: {
    importCompaniesBoxTitle: "Import Companies",
    importCompaniesBoxDescription:
      "Streamline your company's operations by seamlessly importing all your data to maximize efficiency and boost productivity.",

    importCompaniesTitle: "Import Companies",
    importedCompanies: "Imported Companies",
    skippedCompanies: "Skipped Companies",
    titleColumnHeader: "Title",
    taxCodeColumnHeader: "Tax Code",
    typeColumnHeader: "Type",
    vendorIdColumnHeader: "Vendor ID",
    geneaIdColumnHeader: "Genea ID",
    phoneColumnHeader: "Phone",
  },

  importEquipment: {
    importEquipmentBoxTitle: "Import Equipment",
    importEquipmentBoxDescription:
      "Efficiently manage your equipment by importing all relevant data to enhance organization and optimize space utilization",

    importEquipmentTitle: "Import Equipment",
    importedEquipment: "Imported Equipment",
    skippedEquipment: "Skipped Equipment",
    titleColumnHeader: "Title",
    buildingColumnHeader: "Building",
    categoryColumnHeader: "Category",
    manufacturerColumnHeader: "Manufacturer",
    serialNumberColumnHeader: "Serial Number",
    assetTagColumnHeader: "Asset Tag",
    detailsColumnHeader: "Details",
    specificLocationColumnHeader: "Specific Location",
    defaultBuildingColumnHeader: "Default Building",
    defaultFloorColumnHeader: "Default Floor",
    defaultSuiteColumnHeader: "Default Suite",
    attachmentColumnHeader: "Attachments",
  },

  importEquipmentCategories: {
    importEquipmentCategoriesBoxTitle: "Import Equipment Categories",
    importEquipmentCategoriesBoxDescription:
      "Efficiently manage your equipment categories by importing all relevant data to enhance organization and optimize space utilization",

    importedEquipmentCategories: "Imported Equipment Categories",
    skippedEquipmentCategories: "Skipped Equipment Categories",
    importEquipmentCategoriesTitle: "Import Equipment Categories",
    titleColumnHeader: "Title",
  },
  importFloors: {
    importFloorsBoxTitle: "Import Floors",
    importFloorsBoxDescription:
      "Efficiently manage your floors by importing all relevant data to enhance organization and optimize space utilization",

    importedFloors: "Imported Floors",
    skippedFloors: "Skipped Floors",
    importFloorsTitle: "Import Floors",
  },
  importIncidents: {
    importIncidentsBoxTitle: "Import Incidents",
    importIncidentsBoxDescription:
      "Efficiently manage your incidents by importing all relevant data to enhance organization and optimize space utilization",

    importIncidentsTitle: "Import Incidents",
    importedIncidents: "Imported Incidents",
    skippedIncidents: "Skipped Incidents",
    buildingColumnHeader: "Building",
    categoryColumnHeader: "Category",
    requestedForColumnHeader: "Requested For",
    companyColumnHeader: "Company",
    statusColumnHeader: "Status",
    detailsColumnHeader: "Details",
    floorColumnHeader: "Floor",
    suiteColumnHeader: "Suite",
    startDateColumnHeader: "Start Date",
    timezoneColumnHeader: "Timezone",
    attachmentsColumnHeader: "Attachments",
  },
  importMaterialGroups: {
    importMaterialGroupsBoxTitle: "Import Material Groups",
    importMaterialGroupsBoxDescription:
      "Efficiently manage your material groups by importing all relevant data to enhance organization and optimize space utilization",

    importMaterialGroupsTitle: "Import Material Groups",
    importedMaterialGroups: "Imported Material Groups",
    skippedMaterialGroups: "Skipped Material Groups",
    titleColumnHeader: "Title",
  },
  importMaterials: {
    importMaterialsBoxTitle: "Import Materials",
    importMaterialsBoxDescription:
      "Efficiently manage your materials by importing all relevant data to enhance organization and optimize space utilization",

    importMaterialsTitle: "Import Materials",
    importedMaterials: "Imported Materials",
    skippedMaterials: "Skipped Materials",
    titleColumnHeader: "Title",
    groupColumnHeader: "Group",
    priceColumnHeader: "Price",
    detailsColumnHeader: "Details",
    markupColumnHeader: "Markup",
    taxColumnHeader: "Tax",
    glAccountColumnHeader: "GL Account",
    arCodeColumnHeader: "AR Code",
    chargeCodeColumnHeader: "Charge Code",
  },
  importProcedures: {
    importProceduresBoxTitle: "Import Procedures",
    importProceduresBoxDescription:
      "Efficiently manage your procedures by importing all relevant data to enhance organization and optimize space utilization",

    importProceduresTitle: "Import Procedures",
    importedProcedures: "Imported Procedures",
    skippedProcedures: "Skipped Procedures",
    sectionsTitle: "Sections",
    sectionTitlePlaceholder: "Enter section title",
    sectionTitleError: "Section title is required",
    noSectionsError: "There are no sections. Please add one.",

    oneSection: "1 section",
    sections: "sections",
    noSections: "No sections",
    sectionsTooltip:
      "Every section must have title and atleast one step! Every step must have title!",
    toastAtleastOneSectionRequired: "Atleast one section is required!",
    toastEverySectionMustHaveTitleAndAtleastOneStep:
      "Every section must have title and atleast one step! Every step must have title!",

    titleColumnHeader: "Title",
    estimatedLaborColumnHeader: "Estimated Labor",
    notesColumnHeader: "Notes",
    sectionsColumnHeader: "Sections",
  },
  importSchedules: {
    importSchedulesBoxTitle: "Import Schedules",
    importSchedulesBoxDescription:
      "Efficiently manage your schedules by importing all relevant data to enhance organization and optimize space utilization",

    importSchedulesTitle: "Import Schedules",
    importedSchedules: "Imported Schedules",
    skippedSchedules: "Skipped Schedules",

    buildingColumnHeader: "Building",
    titleColumnHeader: "Title",
    notesColumnHeader: "Notes",
    taskTypeColumnHeader: "Task Type",
    assignedToColumnHeader: "Assigned To",
    proceduresColumnHeader: "Procedures",
    equipmentColumnHeader: "Equipment",
    equipmentAsTaskColumnHeader: "Equipment As Task?",
    recurrenceColumnHeader: "Recurrence",
    startDateColumnHeader: "Start Date",
    timezoneColumnHeader: "Timezone",
    dueAfterColumnHeader: "Due After",
    skippedDatesColumnHeader: "Skipped Dates",

    equipmentAsTaskTooltip:
      "If enabled system will create separate task for each selected equipment.",
  },
  importSuites: {
    importSuitesBoxTitle: "Import Suites",
    importSuitesBoxDescription:
      "Efficiently manage your suites by importing all relevant data to enhance organization and optimize space utilization",

    importSuitesTitle: "Import Suites",
    importedSuites: "Imported Suites",
    skippedSuites: "Skipped Suites",

    titleColumnHeader: "Title",
    typeColumnHeader: "Type",
    floorColumnHeader: "Floor",
    sizeColumnHeader: "Size",
    bomaSizeColumnHeader: "BOMA Size",
    colorColumnHeader: "Color",
    isPublicColumnHeader: "Is Public?",
  },
  importUsers: {
    importUsersBoxTitle: "Import Users",
    importUsersBoxDescription:
      "Effortlessly import all your user data to optimize productivity and save valuable time.",

    importUsersTitle: "Import Users",

    firstNameColumnHeader: "First Name",
    lastNameColumnHeader: "Last Name",
    emailColumnHeader: "Email",
    titleColumnHeader: "Title",
    phoneColumnHeader: "Phone",
    roleColumnHeader: "Role",
    buildingsColumnHeader: "Buildings",
    fullAccessBuildingsColumnHeader: "Full Access Buildings",
    companiesColumnHeader: "Companies",
    fullAccessCompaniesColumnHeader: "Full Access Companies",

    importedUsers: "Imported Users",
    skippedUsers: "Skipped Users",
  },
  importWorkOrderTemplates: {
    importWorkOrderTemplatesBoxTitle: "Import Work Order Templates",
    importWorkOrderTemplatesBoxDescription:
      "Efficiently manage your work order templates by importing all relevant data to enhance organization and optimize space utilization",

    importWorkOrderTemplatesTitle: "Import Work Order Templates",
    importedWorkOrderTemplates: "Imported Work Order Templates",
    skippedWorkOrderTemplates: "Skipped Work Order Templates",

    buildingColumnHeader: "Building",
    issueTypeColumnHeader: "Issue Type",
    requestedByColumnHeader: "Requested By",
    companyColumnHeader: "Company",
    assignedToColumnHeader: "Assigned To",
    priorityColumnHeader: "Priority",
    statusColumnHeader: "Status",
    detailsColumnHeader: "Details",
    floorColumnHeader: "Floor",
    suiteColumnHeader: "Suite",
    recurrenceColumnHeader: "Recurrence",
    startDateColumnHeader: "Start Date",
    timezoneColumnHeader: "Timezone",
    dueAfterColumnHeader: "Due After",
    skippedDatesColumnHeader: "Skipped Dates",
    attachmentsColumnHeader: "Attachments",
  },

  importWorkOrders: {
    importWorkOrdersBoxTitle: "Import Work Orders",
    importWorkOrdersBoxDescription:
      "Efficiently manage your work orders by importing all relevant data to enhance organization and optimize space utilization",

    importWorkOrdersTitle: "Import Work Orders",
    importedWorkOrders: "Imported Work Orders",
    skippedWorkOrders: "Skipped Work Orders",

    buildingColumnHeader: "Building",
    issueTypeColumnHeader: "Issue Type",
    requestedByColumnHeader: "Requested By",
    companyColumnHeader: "Company",
    assignedToColumnHeader: "Assigned To",
    priorityColumnHeader: "Priority",
    statusColumnHeader: "Status",
    detailsColumnHeader: "Details",
    floorColumnHeader: "Floor",
    suiteColumnHeader: "Suite",
    startDateColumnHeader: "Start Date",
    dueDateColumnHeader: "Due Date",
    closedAtColumnHeader: "Closed At",
    timezoneColumnHeader: "Timezone",
    attachmentsColumnHeader: "Attachments",
  },
};

// To update translation do JSON.stringify(translation) and replace the file
// console.log(JSON.stringify(translation));

export default translation;
